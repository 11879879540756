<template>
  <div class="fill-height">
    <template
      v-if="$store.state.pages.Search.features.enable_filters && $route.params.searchId && loaded"
    >
      <v-toolbar
        class="w-100pc bb-1"
        height="60"
        style="z-index: 1"
        absolute
        flat
      >
        <v-row class="c-black">
          <v-col
            class="d-flex align-center"
            cols="9"
            md="10"
          >
            <FilterBar
              v-model="search"
              @change="updateSearch()"
            />

            <template v-if="lists.length > 0">
              <FilterMenu
                :title="$vuetify.display.lgAndUp ? 'Favorites' : ''"
                tid="search_favorite_filter"
              >
                <template #icon-prefix>
                  <v-icon class="fs-24 me-2 c-primary"> favorite </v-icon>
                </template>
                <template #icon-suffix>
                  <v-icon
                    class="fs-24 me-2 c-primary hidden"
                    aria-hidden
                  />
                </template>
                <template #card>
                  <div
                    v-for="list in lists"
                    :key="list.id"
                    class="pa-3 hover-bg-primary-lite"
                  >
                    <v-checkbox
                      v-model="listFilter"
                      @update:model-value="updateSearch()"
                      :label="list.name"
                      :value="list"
                      class="mt-0"
                      color="primary"
                      hide-details
                    />
                  </div>
                </template>
              </FilterMenu>
            </template>
          </v-col>

          <template v-if="$store.state.pages.Search.download.enabled">
            <v-col
              class="d-flex justify-end align-center"
              cols="3"
              md="2"
            >
              <v-btn
                @click="$refs.download.open()"
                :ripple="false"
                class="d-flex d-lg-none focus-visible ma-0 pa-0"
                data-cy="download_favorites_link_mobile"
                data-tid="download_favorites_link"
                variant="text"
                tracked
              >
                {{ $t('Download') }}
              </v-btn>
              <v-btn
                @click="$refs.download.open()"
                :ripple="false"
                class="d-none d-lg-block focus-visible"
                data-cy="download_favorites_link"
                data-tid="download_favorites_link"
                variant="text"
                tracked
              >
                {{ $t('Download favorites') }}
              </v-btn>
            </v-col>
          </template>

          <Download
            ref="download"
            :lists="lists"
          />
        </v-row>
      </v-toolbar>
    </template>

    <Results
      v-if="search"
      v-show="loaded"
      @change:page="updatePage($event)"
      @favorite:add="createFavorite($event[0], $event[1])"
      @favorite:list="createListAndFavorite($event[0], $event[1])"
      @favorite:remove="removeFavorite($event)"
      @redo="updateSearch"
      ref="results"
      :default-center="default_center"
      :favorites="favorites"
      :list-filters="listFilter"
      :lists="lists"
      :loaded="loaded"
      :matches="matches"
      :page="page"
      :pages="pages"
      :processing="processing"
      :search="search"
      :top="0"
    />
  </div>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import Download from '@/shared/components/search/download.vue';
import FilterBar from '@/shared/components/search/FilterBar.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import Results from '@/shared/components/search/results.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import { SearchParams } from '@/shared/services/search_params';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const allFiltersDialogIsVisible = ref(false);
const default_center = ref({ lat: 37.0902, lng: -95.7129 });
const favorites = ref([]);
const lists = ref([]);
const listFilter = ref(null);
const loaded = ref(false);
const matches = ref([]);
const moreFiltersDialogIsVisible = ref(false);
const page = ref(parseInt(route.query.page, 10) || 1);
const pages = ref(null);
const previousParams = ref(null);
const processing = ref(false);
const results = ref();
const search = ref({});

function createFavorite(listId, providerId) {
  Api.favorite.create({ list_id: listId, provider_id: providerId }, () => {
    loadFavorites();
  });
}

function createListAndFavorite(listName, providerId) {
  Api.list.create({ name: listName }, (resp) => {
    createFavorite(resp.data.id, providerId);
  });
}

function removeFavorite(favoriteId) {
  Api.favorite.destroy(favoriteId, () => {
    loadFavorites();
  });
}

function load() {
  matches.value = [];
  Api.search.get(
    route.params.searchId,
    (resp) => {
      store.commit('setSearch', resp.data);
      refreshSearchResults(resp.data);
    },
    () => {
      router.replace({ path: '/' });
    },
  );
}

function loadFavorites() {
  Api.list.index((resp) => {
    lists.value = resp.data;
  });

  Api.favorite.index((resp) => {
    favorites.value = resp.data;
  });
}

function refreshSearchResults(data) {
  processing.value = true;
  matches.value = [];
  if (data) {
    let { distance } = data;
    if (distance) distance = parseInt(distance, 10);
    search.value = { ...data, distance };
    store.commit('setSearch', data);
  }

  const filters = { locale: i18n.locale, page: page.value };
  if (listFilter.value) {
    filters.list_id = listFilter.value.id;
  }

  Api.match.index(route.params.searchId, filters, (resp) => {
    const returningToFavoriteProvider = route.query.providerId;
    if (returningToFavoriteProvider) {
      store.commit('changeMode', 'provider');
    }

    loaded.value = true;
    matches.value = resp.data;
    processing.value = false;
    pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
    results.value.triggerRefit();

    if (returningToFavoriteProvider) {
      nextTick(() => {
        const selectedProviderIndex = matches.value.findIndex(
          (match) => match.id === route.query.providerId,
        );
        results.value.select(selectedProviderIndex);
      });
    } else {
      store.commit('changeMode', 'list');
      setTimeout(() => {
        const resultsRefs = results.value.$refs || {};
        if (resultsRefs.match && resultsRefs.match[0]) {
          resultsRefs.match[0].$el.focus();
        } else {
          resultsRefs.noMatch.focus();
        }
      }, 400);
    }
  });
}

function updatePage(newPage) {
  router.push({ query: { newPage } });
  page.value = newPage;
  refreshSearchResults();
}

function updateSearch(bounds) {
  if (bounds) {
    search.value.sw_lat = bounds[0].lat();
    search.value.sw_lng = bounds[0].lng();
    search.value.ne_lat = bounds[1].lat();
    search.value.ne_lng = bounds[1].lng();
  } else {
    search.value.sw_lat = null;
    search.value.sw_lng = null;
    search.value.ne_lat = null;
    search.value.ne_lng = null;
  }

  moreFiltersDialogIsVisible.value = false;
  allFiltersDialogIsVisible.value = false;

  const updatedParams = new SearchParams(search.value, {
    ageGroups: search.value.ageGroups,
    listFilterId: listFilter.value?.id,
    dobSearch: store.state.pages.Search.enable_dob_search,
  }).asJson();

  if (previousParams.value !== JSON.stringify(updatedParams)) {
    processing.value = true;
    previousParams.value = JSON.stringify(updatedParams);
    page.value = 1;
    Api.search.update(
      route.params.searchId,
      updatedParams,
      (resp) => {
        refreshSearchResults(resp.data);
      },
      (error) => {
        eventBus.chime(error.response.data.errors[0]);
        matches.value = [];
        processing.value = false;
      },
    );
  }
}

onMounted(() => {
  load();
  search.value.exclude_closed = store.state.pages.Search.features.enable_closed_filter;
  eventBus.on('search-update', (value) => {
    search.value = value;
    updateSearch();
  });

  loadFavorites();
});
</script>
