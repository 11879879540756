<template>
  <v-container
    v-show="loaded"
    class="pt-16"
  >
    <v-card
      class="mx-auto"
      max-width="800"
      flat
      tile
    >
      <v-card-title>
        <h1
          class="fs-28 text-wrap"
          tabindex="0"
        >
          {{ $t('Which child would you like to create an application for?') }}
        </h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-radio-group
              v-model="selectedChild"
              class="mb-5"
              hide-details
            >
              <RadioButton
                v-for="(child, index) in children"
                :key="child.id"
                :gap="radioButtonGap(index)"
                :subtitle="child.dob"
                :title="fullName(child)"
                :value="child"
                subtitle-icon="cake"
              />
            </v-radio-group>

            <v-btn
              @click="router.push({ name: 'ChildNew' })"
              color="primary"
            >
              <v-icon
                class="mr-1"
                size="16"
              >
                add
              </v-icon>
              {{ $t('Add another child') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :to="{ name: 'Dashboard' }"
          variant="text"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="router.push({ name: 'ChildApply', params: { childId: selectedChild.id } })"
          :disabled="!selectedChild"
          color="primary"
        >
          {{ $t('Continue') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script setup>
import RadioButton from '@/shared/components/form/RadioButton.vue';
import Api from '@/shared/services/bright_finder';
import { onMounted } from 'vue';
import { capitalize } from '@/plugins/filters';
import { useRouter } from 'vue-router';

const router = useRouter();

const children = ref([]);
const group = ref(null);
const loaded = ref(false);
const selectedChild = ref(null);

function fullName(child) {
  let result = capitalize(child.first_name);
  if (child.middle_name) result += ` ${capitalize(child.middle_name)}`;
  result += ` ${capitalize(child.last_name)}`;

  return result;
}

async function load() {
  await loadGroup();
  await loadChildren();
  loaded.value = true;
}

async function loadChildren() {
  const resp = await Api.parent.children.index();
  children.value = resp.data;
}

async function loadGroup() {
  const resp = await Api.group.get();
  group.value = resp.data;
}

function radioButtonGap(index) {
  return index === 0 ? null : 5;
}

onMounted(async () => {
  await load();
});
</script>
