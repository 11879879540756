<template>
  <ResourceDialog
    ref="dialog"
    fullscreen
  >
    <template #form>
      <div v-if="search">
        <div v-if="mode == 'location'">
          <template v-if="searchFlags.regionSearchEnabled">
            <div class="fs-20 mb-4">
              {{ $t('Search by zip code, city or county') }}
            </div>
            <v-text-field
              v-model="search.unformatted_origin"
              @keyup.enter="submitDisabled ? false : submit()"
              :placeholder="$t('Enter zip code, city or county')"
              variant="outlined"
              hide-details
            />
          </template>
          <template v-else>
            <div class="c-black fs-18 fw-600 mb-2">
              {{ $t('Search for care near:') }}
            </div>
            <SearchLocation
              :value="search"
              hide-close
            />
          </template>
        </div>
        <div v-if="mode == 'mode'">
          <div class="c-black fs-18 fw-600 mb-2">
            {{ $t('Show commutes by:') }}
          </div>
          <v-list
            v-model:selected="search.travel_mode"
            mandatory
          >
            <v-list-item
              v-for="type in $a.assets.transit_options"
              :key="type.value"
              :value="type.value"
            >
              <v-list-item-title>{{ $t(type.text) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="mode == 'children' && !searchFlags.dobSearchEnabled">
          <div class="c-black fs-18 fw-600 mb-2">
            {{ $t('Add children:') }}
          </div>
          <LabeledVolume
            v-for="(item, index) in ageGroups"
            @decrease="$emit('change:count', [index, -1])"
            @increase="$emit('change:count', [index, 1])"
            :key="index"
            ref="smChildVolume"
            :count="item.count"
            :dense="true"
            :subtitle="item.subtitle || item.subtext"
            :title="item.title || item.text"
          />
        </div>

        <div v-if="mode == 'children' && searchFlags.dobSearchEnabled">
          <SearchDobChild
            @on-add-child="(child) => $emit('onAddChild', child)"
            @on-remove-child="(index) => $emit('onRemoveChild', index)"
            @on-update-child="(child, index) => $emit('onUpdateChild', child, index)"
            ref="searchDobChild"
            :children="children"
            :is-mobile="true"
          />
        </div>
      </div>
    </template>

    <template #actions>
      <v-btn
        @click="close"
        :disabled="searchDialogDoneDisabled"
        :ripple="false"
        class="focus-very-visible"
        color="primary"
        data-cy="done_button"
        block
      >
        {{ $t('Done') }}
      </v-btn>
    </template>
  </ResourceDialog>
</template>

<script>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SearchLocation from '@/shared/components/search/location.vue';
import SearchDobChild from '@/parent/components/searches/SearchDobChild.vue';
import SearchFlags from '@/parent/services/search-flags';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SearchLocation,
    SearchDobChild,
    ResourceDialog,
  },

  props: {
    ageGroups: {
      type: Array,
      default() {
        return [];
      },
    },
    children: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  emits: ['change', 'change:count', 'onAddChild', 'onRemoveChild', 'onUpdateChild'],

  data() {
    return {
      mode: null,
      search: null,
      searchFlags: new SearchFlags(this.$store),
    };
  },

  computed: {
    searchDialogDoneDisabled() {
      if (
        this.mode === 'location' &&
        !this.search.unformatted_origin &&
        !this.search.formatted_origin
      ) {
        return true;
      }
      if (this.mode === 'mode' && !this.search.travel_mode) return true;
      if (this.mode === 'children' && this.childrenDoneDisabled) return true;

      return false;
    },

    childrenDoneDisabled() {
      if (this.searchFlags.regionSearchEnabled) return false;

      let count = 0;

      if (this.searchFlags.dobSearchEnabled) {
        count = this.children?.filter(
          (child) => child.dob !== null && child.care_needed_date !== null,
        ).length;
      } else {
        count = this.ageGroups?.filter((ageGroup) => ageGroup.count > 0).length;
      }

      return count === 0;
    },
  },

  methods: {
    close() {
      if (this.searchFlags.dobSearchEnabled && this.mode === 'children') {
        // We need to handle the children menu differently based on the UX specific to this feature
        // only when dob search is enabled

        // Checks for validity (func returns false if invalid)
        if (this.$refs.searchDobChild?.handleDoneClick() === true) {
          this.handleClose();
        }
      } else {
        this.handleClose();
      }
    },

    handleClose() {
      this.$emit('change', this.search);
      this.$refs.dialog.close();
      this.mode = null;
      this.search = null;
    },

    open(mode, search) {
      this.mode = mode;
      this.search = search;
      this.$refs.dialog.open();
    },
  },
};
</script>
