<template>
  <div
    v-if="$store.state.site"
    :class="$route.meta.fillHeight ? 'fill-height' : ''"
  >
    <v-app :class="vAppClass">
      <template v-if="$store.state.pages.Welcome.alert && $route.name == 'Welcome'">
        <v-system-bar
          class="bg-primary c-white alert-bar"
          height="80"
          order="0"
          role="alert"
        >
          <div class="pe-2 w-100pc text-center wrapper">
            <ul class="skip-links">
              <a
                class="skip-link"
                href="#main"
                >Skip to main content</a
              >
            </ul>
            <v-icon class="d-inline c-white pe-2"> info </v-icon>
            <div class="alert-wrapper">
              <MarkdownContent
                :content="$store.state.pages.Welcome.alert"
                class="alert-css"
              />
            </div>
          </div>
        </v-system-bar>
      </template>

      <template
        v-if="
          $store.state.pages.Navigation.StructuredAlert &&
          $store.state.pages.Navigation.StructuredAlert.length > 0 &&
          alertIsVisible
        "
      >
        <v-system-bar
          class="bg-primary c-white"
          order="1"
          window
        >
          <div class="mx-auto">
            <v-icon class="d-inline c-white pe-2"> info </v-icon>
            <StructuredText :text="$store.state.pages.Navigation.StructuredAlert" />
            <v-btn
              @click="alertIsVisible = false"
              :ripple="false"
              aria-label="Close"
              icon="close"
              size="x-small"
              variant="plain"
            />
          </div>
        </v-system-bar>
      </template>

      <template v-if="$vuetify.display.mdAndUp">
        <v-app-bar
          :class="$route.meta.expandedNav ? '' : 'bb-1 bc-outlined-gray'"
          class="bg-white"
          order="2"
          flat
        >
          <v-row class="d-flex align-center justify-space-between px-4">
            <v-col
              class="d-flex align-center justify-start"
              cols="12"
              lg="8"
              md="8"
            >
              <ul class="skip-links">
                <a
                  class="skip-link"
                  href="#main"
                  >Skip to main content</a
                >
              </ul>
              <img
                @click="$router.push({ path: '/' })"
                @keyup.enter="$router.push({ path: '/' })"
                id="app_bar_logo"
                :alt="$t(`Homepage for ${$store.state.brand.name}`)"
                :src="$store.state.brand.logo.full"
                class="pointer mxh-40 mxw-100pc me-8"
                role="link"
                tabindex="0"
                tracked
              />
              <template v-if="$store.state.profile">
                <template v-if="$route.name == 'SearchShow'">
                  <GlobalSearch />
                </template>

                <template v-else-if="$route.name == 'Welcome'">
                  <template v-if="$store.state.profile.search_id">
                    <v-btn
                      :to="{
                        name: 'SearchShow',
                        params: { searchId: $store.state.profile.search_id },
                      }"
                      class="focus-visible me-3"
                      variant="text"
                    >
                      <v-icon class="me-3"> west </v-icon>
                      <span>{{ $t('Back to search') }}</span>
                    </v-btn>
                  </template>
                </template>
              </template>
            </v-col>

            <v-col
              class="ta-right va-middle d-flex align-center justify-end"
              cols="12"
              lg="4"
              md="4"
            >
              <template v-if="$store.state.config.enable_search">
                <GlobalQuery />
              </template>

              <LocaleSelector />

              <AccountMenu @sign-out="signOut" />
            </v-col>
          </v-row>
        </v-app-bar>
      </template>

      <template v-else>
        <v-app-bar
          :height="
            $route.name == 'SearchShow' ? ($store.state.expandAppBarSmAndDown ? 225 : 124) : '64'
          "
          class="bg-white"
          elevation="0"
          order="3"
        >
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col
              class="d-flex align-center justify-start"
              cols="3"
            >
              <ul class="skip-links">
                <a
                  class="skip-link"
                  href="#main"
                  >Skip to main content</a
                >
              </ul>
              <img
                @click="$router.push({ path: '/' })"
                :alt="$t(`Homepage for ${$store.state.brand.name}`)"
                :src="$store.state.brand.logo.full"
                class="pointer mxh-40 mxw-100pc"
              />
            </v-col>

            <v-col
              class="d-flex align-center justify-end"
              cols="9"
            >
              <template v-if="$store.state.config.enable_search">
                <div class="d-inline-flex">
                  <GlobalQuery />
                </div>
              </template>

              <LocaleSelector />

              <AccountMenu @sign-out="signOut" />
            </v-col>

            <template v-if="$route.name == 'SearchShow'">
              <v-col cols="12">
                <GlobalSearch :expanded="$store.state.expandAppBarSmAndDown" />
              </v-col>
            </template>
          </v-row>
        </v-app-bar>
      </template>

      <SnackBar
        v-model="chime"
        :centered="true"
        :text="chimeText"
        :timeout="chimeTime"
        rounded
      />

      <v-main
        id="main"
        class="layout--content fill-height"
      >
        <router-view
          @chime="regularChime"
          @error="error"
          @replace="$router.push($event)"
          v-slot="{ Component }"
        >
          <transition
            :name="transitionName"
            mode="out-in"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </v-main>

      <UserSessionTimeout />
    </v-app>
  </div>
</template>

<script>
import AccountMenu from '@/parent/components/AccountMenu.vue';
import Api from '@/shared/services/bright_finder';
import GlobalQuery from '@/shared/components/GlobalQuery.vue';
import GlobalSearch from '@/parent/components/searches/GlobalSearch.vue';
import LanguageHelper from '@/shared/mixins/LanguageHelper';
import UserSessionTimeout from '@/shared/components/UserSessionTimeout.vue';
import LocaleSelector from '@/shared/components/LocaleSelector.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import SnackBar from '@/shared/components/SnackBar.vue';
import StructuredText from '@/shared/components/content/StructuredText.vue';
import useSegmentPageViews from '@/shared/composables/analytics/useSegmentPageViews';
import chime from '@/shared/mixins/chime';
import SegmentService from '@/shared/services/analytics/SegmentService';
import { provide } from 'vue';
import { SEGMENT_PROVIDE_KEY } from '@/shared/composables/analytics/useSegment';

export default {
  compatConfig: { MODE: 3 },

  components: {
    AccountMenu,
    GlobalQuery,
    GlobalSearch,
    MarkdownContent,
    StructuredText,
    LocaleSelector,
    SnackBar,
    UserSessionTimeout,
  },

  mixins: [chime, LanguageHelper],

  setup() {
    const segment = SegmentService.forParent(window.analytics);
    provide(SEGMENT_PROVIDE_KEY, segment);
    useSegmentPageViews(segment);
  },

  data() {
    const protocol = window.location.href.includes('https') ? 'https' : 'http';
    let baseUrlSuffix = import.meta.env.VUE_BASE_URL ?? '';
    baseUrlSuffix = baseUrlSuffix.startsWith('/') ? baseUrlSuffix : `/${baseUrlSuffix}`;
    const baseUrl = `${protocol}://${window.location.host}${baseUrlSuffix}`;

    return {
      alertIsVisible: true,
      navDrawerIsVisible: false,
      queryFocused: false,
      root_url: `${baseUrl}/dashboard`,
      settings_url: `${baseUrl}/settings`,
      sign_out_url: baseUrl,
      transitionName: '',
      query: null,
    };
  },

  computed: {
    vAppClass() {
      return [
        this.$route.meta.background || 'bg-white',
        { 'fill-height': this.$route.meta.fillHeight },
      ];
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from = { meta: {}, query: {} }) {
        if (to.meta.depth > from.meta.depth) {
          this.transitionName = 'slide-left';
        } else {
          this.transitionName = 'slide-right';
        }
      },
    },
  },

  methods: {
    signOut() {
      setTimeout(() => {
        Api.member.session.destroy(() => {
          const signOutUrl = new URL(this.sign_out_url);
          signOutUrl.searchParams.set('lang', this.$i18n.locale);
          window.location.href = signOutUrl.href;
        });
      }, 200);
    },
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
}

.alert-bar {
  max-height: 100px;
  overflow-y: auto;
  padding: 0;
}

.alert-css {
  display: inline-block;
  white-space: pre-wrap;
  margin-top: 0;
  padding-top: 0;
  flex: 1;
}

.alert-wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}

.icon-wrapper {
  /* flex aligns icon with text across multiple lines of text  */
  display: flex;
  padding-top: 10px;
}

@media (max-width: 37.5rem) {
  img {
    padding-left: 0.3125rem;
    margin-left: 0.3125rem;
  }
}
</style>
