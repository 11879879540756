import '@/plugins/data-dog';
import '@/plugins/uppy';
import '@/shared/services/google_dev_mock';
import '@/styles/main.scss';
import Api from '@/shared/services/bright_finder';
import App from '../src/parent/App.vue';
import GoogleTagManagerLoader from '@/plugins/google_tag_manager_loader';
import assets from '@/shared/services/assets';
import customStyles from '@/plugins/custom-styles';
import i18n from '@/plugins/i18n';
import metaTags from '@/plugins/meta-tags';
import router from '../src/parent/router';
import store from '../src/parent/store';
import vuetify from '@/plugins/vuetify';

import { EventBus } from '@/plugins/event-bus';
import { createApp, configureCompat } from 'vue';
import { handleProtectedRoute } from '@/parent/services/request-routing';
import { hideInaccessibleRecaptchaElements } from '@/plugins/a11y';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import translate from '@/shared/directives/translate';
import handleNativeClickEvent from '@/shared/utils/analytics/handleNativeClickEvent';

configureCompat({
  MODE: 3,
});

router.beforeEach((to) => {
  if (to.meta.public || to.path === '/sign_out') return true;

  const { config, profile } = store.state;
  const needsPassword =
    config.enable_password_authentication &&
    (to.query.password_reset === 'true' || profile?.password_reset_required === true);
  if (needsPassword && to.name !== 'RegisterPassword') {
    return { name: 'RegisterPassword', query: { password_reset: to.query.password_reset } };
  }

  return handleProtectedRoute({ store, to });
});

Api.public_api.site.configuration.get((resp) => {
  const config = resp.data;
  window.api_url = config.api_url;
  window.organization_id = config.organization_id;
  window.parent_organization_id = config.parent_organization_id;
  window.site_id = config.site_id;

  const app = createApp(App);

  store.commit('setRole', 'parent'); // Vue3 global access
  app.config.globalProperties.$role = 'parent';
  app.config.globalProperties.$a = assets;
  app.provide('assets', assets);
  app.config.globalProperties.$eventBus = EventBus;
  app.provide('eventBus', EventBus);
  app.config.globalProperties.window = window;

  app.use(i18n);
  app.use(router);
  app.use(store);
  app.use(vuetify);

  app.directive('translate', translate);

  if (config.env.VUE_APP_RECAPTCHA_SITE_KEY) {
    app.use(VueReCaptcha, { siteKey: config.env.VUE_APP_RECAPTCHA_SITE_KEY });
  }

  setConfig();

  app.mount('#app');

  resetVuetifyTheme();

  customStyles.generate(store.state.brand.colors);
  document.getElementById('favicon').setAttribute('href', config.favicon_url);
  metaTags.set(config);

  document.addEventListener('click', (event) => {
    store.commit('resetTimeout');

    handleNativeClickEvent(event);
  });

  if (store.state.site.google_tag_manager_id) {
    GoogleTagManagerLoader.activateGtm(
      window,
      document,
      'script',
      'dataLayer',
      store.state.site.google_tag_manager_id,
    );
  }

  store.dispatch('page');

  hideInaccessibleRecaptchaElements();

  function setConfig() {
    store.commit('setBrand', config.brand);
    store.commit('setConfig', config);
    store.commit('setFeatures', config.features);
    store.commit('setPages', config.pages);
    store.commit('setLanguages', config.languages);
    store.commit('setSchemas', config.schemas);
    store.commit('setSite', config.site);
    store.commit('setAgeGroups', config.age_groups);
    store.commit('setSiteWebpageId', config.site_webpage_id);
    store.commit('setUserSessionTimeout', config.user_session_timeout_minutes);
  }

  function resetVuetifyTheme() {
    vuetify.theme.themes.value.light.colors.primary = store.state.brand.colors.primary;
    vuetify.theme.themes.value.light.colors.secondary = store.state.brand.colors.secondary;
    vuetify.theme.themes.value.light.colors.accent = store.state.brand.colors.accent;
  }
});
