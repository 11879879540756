<template>
  <v-container class="mxw-1000 mx-auto py-12">
    <SubsidyProgramValidation :subsidy-program="familySubsidyProgram">
      <template
        v-if="loaded"
        #open
      >
        <span class="c-primary font-weight-bold px-7">
          {{ renderText(familySubsidyProgram?.name) }}
        </span>
        <div
          ref="progressBar"
          :aria-label="t('Progress indicator - question') + ' ' + stepCount"
          class="px-7 focus-invisible"
          role="progressbar"
          tabindex="0"
          aria-live
        >
          <v-progress-linear
            v-if="!progressUnknown"
            v-model="progress"
            :indeterminate="!(subsidy && familySubsidyProgram && schema)"
            class="focus-invisible mb-4"
            color="primary"
          />
        </div>

        <div
          v-if="subsidy && familySubsidyProgram && schema"
          class="px-md-4"
        >
          <QuestionSet
            v-model="subsidy"
            @back="backFromEligibilityQuestion"
            @change:attachments="loadAttachments()"
            @next="forwardFromEligibilityQuestion($event)"
            :attachment-group-id="subsidy.group_id"
            :attachment-owner-id="subsidy.id"
            :attachment-owner-type="'FamilySubsidy'"
            :attachments="familySubsidyAttachments"
            :key-name="sections.eligibilityQuestions"
            :processing="processing"
            :questions="validEligibilityQuestions"
            :reversible="section != getStartingSection()"
            :schema="schema.definition"
            :section="section"
          />

          <template v-if="section == sections.eligibility">
            <FormQuestion
              @back="backFrom(sections.eligibility)"
              @finish="exit"
              @next="forwardFromEligibilitySection"
              :key="sections.eligibility"
              :finishable="!subsidy.projected_eligibility"
              :forwardable="subsidy.projected_eligibility"
              :processing="processing"
              :subtitle="eligibilitySubtitle"
              :title="eligibilityTitle"
              class="focus-very-visible"
              finish-text="Exit"
              next-text="Continue"
              next-arrow
            >
              <SubsidyEligibilityCard
                :key="subsidy.id"
                :elevation="2"
                :funding-sources="subsidy.eligibility_determinations"
                :projected-eligibility="subsidy.projected_eligibility"
                expanded
                outlined
              />

              <v-alert
                v-if="!subsidy.projected_eligibility"
                border="start"
                class="w-100pc mt-6 fs-20"
                color="primary"
                type="info"
              >
                <div>
                  <div v-t="subsidyIsIneligibleMessage" />
                </div>
              </v-alert>
            </FormQuestion>
          </template>

          <ThirdPartyQuestions
            v-if="section == sections.thirdPartyQuestions"
            @back="backFromThirdPartyQuestions"
            @next="forwardFromThirdPartyQuestions"
            :key="sections.thirdPartyQuestions"
            :model-value="subsidy"
            :next-disabled="thirdPartyContinueDisabled()"
            :processing="processing"
            forwardable
          />

          <QuestionSet
            v-model="subsidy"
            @back="backFromOtherQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromOtherQuestion($event)"
            :attachment-group-id="subsidy.group_id"
            :attachment-owner-id="subsidy.id"
            :attachment-owner-type="'FamilySubsidy'"
            :attachments="familySubsidyAttachments"
            :key-name="sections.otherQuestions"
            :processing="processing"
            :questions="validOtherQuestions"
            :reversible="section != getStartingSection()"
            :schema="schema.definition"
            :section="section"
          />

          <template v-if="section == sections.manageHouseholdMembers">
            <FormQuestion
              @back="backFrom(sections.manageHouseholdMembers)"
              @next="forwardFromHouseholdMemberManagement(sections.manageHouseholdMembers)"
              :key="sections.manageHouseholdMembers"
              :forwardable="true"
              :processing="processing"
              :subtitle="householdMemberSubtitle"
              :title="householdMemberTitle"
              class="focus-very-visible"
              next-text="Continue"
              next-arrow
            >
              <HouseholdMembersCard
                @change="loadHouseholdMembers"
                @is-editing-household-member="(bool) => (isEditingHouseholdMember = bool)"
                :subsidy="subsidy"
                :value="householdMembers"
              />
            </FormQuestion>
          </template>

          <template v-for="(householdMember, householdMemberIndex) in householdMembers">
            <template v-if="householdMemberQuestionRegister[householdMember.id]">
              <QuestionSet
                @back="backFromHouseholdMember($event, householdMemberIndex)"
                @change:attachments="loadAttachments()"
                @input="householdMember = $event"
                @next="forwardFromHouseholdMember($event, householdMemberIndex)"
                :key="sections.householdMember + householdMember.id"
                :attachment-group-id="subsidy.group_id"
                :attachment-owner-id="subsidy.id"
                :attachment-owner-type="'FamilySubsidy'"
                :attachment-tags-supplements="[householdMember.id]"
                :attachments="familySubsidyAttachments"
                :header="householdMember.first_name"
                :key-name="sections.householdMember + householdMemberIndex.toString()"
                :model-value="householdMember"
                :processing="processing"
                :questions="
                  householdMemberQuestionRegister[householdMember.id].filter(
                    (question) => question.valid,
                  )
                "
                :schema="householdMemberSchema.definition"
                :section="section"
              />
            </template>
          </template>

          <div
            v-for="(question, index) in validVerificationQuestions"
            v-show="section == [sections.verificationQuestions, index].join('-')"
            :key="[sections.verificationQuestions, index].join('-')"
          >
            <FormQuestion
              @back="backFromVerificationQuestion(index)"
              @next="forwardFromVerificationQuestion(index)"
              :next-disabled="
                question.verification_mandatory &&
                attachments.filter((attachment) => attachment.tag == question.id).length == 0
              "
              :processing="processing"
              :schema="{}"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  group_id: subsidy.group_id,
                  type: 'FamilySubsidyProgram',
                  id: familySubsidyProgram.id,
                  tag: question.id,
                  tags: [subsidy.id, question.id],
                  description:
                    question.attachment_description ||
                    question.verification_title ||
                    question.verification_subtitle,
                }"
                class="mb-4"
              />

              <AttachmentList
                @change="loadAttachments()"
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                class="mb-6"
              />
            </FormQuestion>
          </div>

          <template v-if="section == sections.confirm">
            <FamilySubsidyApplicationReview
              @back="backFrom(sections.confirm)"
              @change:attachments="loadAttachments()"
              @finish="finish"
              @jump="stepBack($event)"
              key="confirm"
              :attachments="attachments"
              :family-subsidy-attachments="familySubsidyAttachments"
              :family-subsidy-program="familySubsidyProgram"
              :household-member-question-register="householdMemberQuestionRegister"
              :household-member-schema="householdMemberSchema"
              :household-members="householdMembers"
              :schema="schema"
              :sections="sections"
              :subsidy="subsidy"
              :valid-eligibility-questions="validEligibilityQuestions"
              :valid-other-questions="validOtherQuestions"
              :valid-verification-questions="validVerificationQuestions"
            />
          </template>
        </div>
      </template>
    </SubsidyProgramValidation>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FamilySubsidyApplicationReview from '@/parent/components/family_subsidy/FamilySubsidyApplicationReview.vue';
import useMyFamilySubsidyUtils from '@/shared/composables/useMyFamilySubsidyUtils';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import HouseholdMembersCard from '@/shared/components/household-members/HouseholdMembersCard.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useRenderLiquid from '@/shared/composables/useRenderLiquid';
import useFamilySubsidyStepper from '@/parent/composables/useFamilySubsidyStepper';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import SubsidyProgramValidation from '@/shared/components/SubsidyProgramValidation.vue';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const LEAVE_WARNING =
  'You have unsaved changes and have not submitted your application. Are you sure you want to leave your application?';
const ELIGIBILITY_TITLE = 'Here is your eligibility report:';
const ELIGIBILITY_SUBTITLE =
  'Based on the answers you have supplied, you may eligible to apply for child care services. Would you like to apply now? If you think this determination is incorrect, please message our support team.';

const { t } = useI18n();
const { updateQuery } = useRouterHelper();
const store = useStore();
const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const { renderText } = useRenderLiquid();

const attachments = ref([]);
const confirmDialog = ref(null);
// const confirmed = ref(false);
const eligibilitySubtitle = ref(null);
const eligibilityTitle = ref(null);
const familySubsidyAttachments = ref([]);
const familySubsidyProgram = ref(null);
// const familySubsidyQuestions = ref([]);
const finished = ref(false);
// const householdMemberQuestions = ref([]);
const householdMembers = ref([]);
const householdMemberSchema = ref(getHouseholdMemberSchema());
const isEditingHouseholdMember = ref(false);
const loaded = ref(false);
const processing = ref(false);
const progressBar = ref(null);
const progressUnknown = ref(!!route.query.section && !route.query.step);
const schema = ref(null);
const stepCount = ref(0);
const stepTotal = ref(0);
const subsidy = ref(null);

const {
  householdMemberQuestionRegister,
  loadQuestions,
  validate,
  validEligibilityQuestions,
  validOtherQuestions,
  validVerificationQuestions,
} = useMyFamilySubsidyUtils({
  familySubsidyProgram,
  householdMembers,
  subsidy,
});

const {
  backFrom,
  backFromEligibilityQuestion,
  backFromHouseholdMember,
  backFromOtherQuestion,
  backFromThirdPartyQuestions,
  backFromVerificationQuestion,
  forwardFromEligibilityQuestion,
  forwardFromEligibilitySection,
  forwardFromHouseholdMember,
  forwardFromHouseholdMemberManagement,
  forwardFromOtherQuestion,
  forwardFromThirdPartyQuestions,
  forwardFromVerificationQuestion,
  getStartingSection,
  section,
  sections,
  stepBack,
} = useFamilySubsidyStepper({
  assertHouseholdMemberRequirements,
  familySubsidyProgram,
  householdMembers,
  householdMemberQuestionRegister,
  processing,
  save,
  saveHouseholdMember,
  saveThirdPartyData,
  updateQuery,
  validate,
  validEligibilityQuestions,
  validOtherQuestions,
  validVerificationQuestions,
});

const householdMemberSubtitle = computed(() => {
  return familySubsidyProgram.value?.household_members_subtitle;
});

const householdMemberTitle = computed(() => {
  return (
    familySubsidyProgram.value?.household_members_title ||
    'List information for members in the household/family'
  );
});

const progress = computed(() => stepCount.value && (stepCount.value / stepTotal.value) * 100);

const subsidyIsIneligibleMessage = computed(() => {
  return (
    familySubsidyProgram.value?.eligibility_failure_alert ||
    'You are not eligible for this program at this time. Please contact support if you believe this is incorrect.'
  );
});

watch(
  () => route.query.section,
  async (newVal) => {
    stepCount.value = parseInt(route.query.step, 10);
    stepTotal.value = calculateStepTotal();
    section.value = newVal;
    progressBar.value?.focus();
  },
);

onBeforeRouteLeave(async (_to, _from, next) => {
  if (!finished.value) {
    const confirm = await confirmDialog.value.confirmWithText(LEAVE_WARNING);
    if (!confirm) return next(false);
  }

  next();
});

onMounted(async () => {
  await load();
});

async function saveThirdPartyData() {
  const resp = await api.parent.family_subsidy.update({
    id: subsidy.value.id,
    third_party_application: subsidy.value.third_party_application,
    third_party_email: subsidy.value.third_party_email,
    third_party_first_name: subsidy.value.third_party_first_name,
    third_party_last_name: subsidy.value.third_party_last_name,
    third_party_phone_number: subsidy.value.third_party_phone_number,
  });
  if (resp?.status !== 200) return false;

  subsidy.value = resp.data;
  return true;
}

async function assertHouseholdMemberRequirements(section) {
  if (section === sections.manageHouseholdMembers) {
    const membersContainingSelf = householdMembers.value.filter(
      (householdMember) => householdMember.relationship.toLowerCase() === 'self',
    );

    if (membersContainingSelf.length === 0) {
      eventBus.longChime(
        'At least one household member must have the relationship of type “self.” Please add yourself as a household member before proceeding.',
      );
      processing.value = false;
      return;
    }

    if (isEditingHouseholdMember.value === true) {
      eventBus.longChime(
        'One or more household members are incomplete or being edited. Please save these members before proceeding.',
      );
      processing.value = false;
      return;
    }

    if (subsidy.value.household_size !== householdMembers.value.length) {
      eventBus.longChime(
        `${t('You indicated that you have')} ${subsidy.value.household_size} ${t('household members. Please ensure there are')} ${subsidy.value?.household_size} ${t('household members present before proceeding.')}`,
      );
      processing.value = false;
      return;
    }

    await forwardFromHouseholdMember(-1, 0);
  }
}

function calculateStepTotal() {
  const eligibiltiyQuestionCount = validEligibilityQuestions.value.length + 1;
  const thirdPartyQuestionCount = familySubsidyProgram.value.enable_third_party_applications
    ? 1
    : 0;
  const otherQuestionCount = validOtherQuestions.value.length;
  const verificationQuestionCount = validVerificationQuestions.value.length;
  const householdMemberQuestionCount =
    Object.values(householdMemberQuestionRegister.value).reduce(
      (acc, householdMemberQuestions) =>
        acc + householdMemberQuestions.filter((q) => q.valid).length,
      0,
    ) + 1;
  const total =
    eligibiltiyQuestionCount +
    thirdPartyQuestionCount +
    otherQuestionCount +
    verificationQuestionCount +
    householdMemberQuestionCount;

  return total;
}

async function exit() {
  processing.value = true;
  finished.value = true;

  router.push({ name: 'Dashboard' });
}

async function finish(attestationParams) {
  processing.value = true;
  finished.value = true;

  const resp = await api.parent.family_subsidy.submit(subsidy.value, attestationParams);
  processing.value = false;
  if (resp?.status !== 200) return;

  eventBus.longChime('Submitted');
  router.push({ name: 'Dashboard' });
}

function getHouseholdMemberSchema() {
  return Object.values(store.state.schemas).filter(
    (schema) => schema.data_type === 'HouseholdMember',
  )[0];
}

async function load() {
  await loadFamilySubsidy();
  await loadFamilySubsidyProgram();
  await loadFamilySubsidySchema();
  if (familySubsidyProgram.value.enable_household_members) await loadHouseholdMembers();
  await loadQuestions();
  await loadAttachments();

  stepCount.value = parseInt(route.query.step, 10);
  await loadFamilySubsidy();
  await validate();
  stepTotal.value = calculateStepTotal();
  if (route.query.section) {
    section.value = route.query.section;
    progressBar.value?.focus();
  } else {
    router.push({ query: { section: getStartingSection(), step: 1 } });
  }
  document.title = `Continue application - ${familySubsidyProgram.value.name}`;
  loaded.value = true;
}

async function loadFamilySubsidy() {
  const resp = await api.parent.family_subsidy.get(route.params.id);
  subsidy.value = resp.data;
}

async function loadHouseholdMembers() {
  householdMembers.value = (await api.parent.household_member.index()).data;
}

async function loadAttachments() {
  const resp = await api.member.attachment.index();
  if (resp?.status !== 200) return;

  attachments.value = resp.data;
  familySubsidyAttachments.value = resp.data.filter((att) => att.owner_type === 'FamilySubsidy');
}

async function loadFamilySubsidyProgram() {
  const resp = await api.public_api.organization.family_subsidy_program.get(
    subsidy.value.family_subsidy_program_id,
  );
  familySubsidyProgram.value = resp.data;
  eligibilitySubtitle.value =
    familySubsidyProgram.value.eligibility_subtitle || ELIGIBILITY_SUBTITLE;
  eligibilityTitle.value = familySubsidyProgram.value.eligibility_title || ELIGIBILITY_TITLE;
}

async function loadFamilySubsidySchema() {
  const resp = await api.public_api.organization.schema.get(
    familySubsidyProgram.value.family_subsidy_schema_id,
  );
  schema.value = resp.data;
}

async function save() {
  const resp = await api.parent.family_subsidy.update(subsidy.value);
  if (resp?.status !== 200) return false;

  subsidy.value = resp.data;
  return true;
}

async function saveHouseholdMember(householdMember) {
  return api.parent.household_member.update(householdMember);
}

function thirdPartyContinueDisabled() {
  if (
    subsidy.value.third_party_application === false ||
    (subsidy.value.third_party_application &&
      subsidy.value.third_party_email &&
      subsidy.value.third_party_first_name &&
      subsidy.value.third_party_last_name &&
      subsidy.value.third_party_phone_number)
  ) {
    return false;
  }

  return true;
}
</script>
