<template>
  <v-card
    v-if="provider"
    data-cy="selection-card"
    border
    flat
    tile
  >
    <v-row>
      <v-col>
        <template v-if="ordered || !hideToggle">
          <v-row class="px-3 py-3 d-flex align-center">
            <v-col class="d-flex align-center">
              <template v-if="ordered">
                <template v-if="readonly">
                  <SelectionRanking :index="index" />
                </template>
                <template v-else>
                  <v-btn
                    @click.stop.prevent="$emit('move', -1)"
                    :disabled="!(index > 0)"
                    class="focus-very-visible me-3"
                    color="primary"
                    data-cy="selection-down"
                    data-testid="selection-down-button"
                    size="small"
                    variant="outlined"
                    icon
                  >
                    <v-icon>expand_less</v-icon>
                  </v-btn>
                  <SelectionRanking :index="index" />
                  <v-btn
                    @click.stop.prevent="$emit('move', 1)"
                    :disabled="!(length > index + 1)"
                    class="ms-3 focus-very-visible"
                    color="primary"
                    data-cy="selection-up"
                    data-testid="selection-up-button"
                    size="small"
                    variant="outlined"
                    icon
                  >
                    <v-icon>expand_more</v-icon>
                  </v-btn>
                </template>
              </template>
            </v-col>

            <v-col
              v-if="!readonly && !hideToggle"
              class="d-flex justify-end"
              cols="4"
            >
              <v-btn
                @click="$emit('toggle', false)"
                color="red"
                size="small"
              >
                {{ $t('Remove') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-divider />
        </template>

        <div class="c-black pa-3">
          <v-row
            v-if="provider.accepts_subsidy || provider.closed_status != 'Open'"
            class="mb-2"
            dense
          >
            <v-col>
              <v-chip
                v-if="provider.closed_status != 'Open'"
                class="me-2"
                color="red"
              >
                <v-icon
                  class="c-white fs-16 me-2"
                  size="24"
                >
                  warning
                </v-icon>
                <span class="fw-600">
                  {{ $t(provider.closed_status) }}
                </span>
              </v-chip>
              <v-chip
                v-if="provider.accepts_subsidy"
                color="secondary"
                variant="outlined"
              >
                {{ $t('Accepts Financial Aid') }}
              </v-chip>
            </v-col>
          </v-row>

          <v-row
            class="ma-0 pa-0 d-flex align-center"
            dense
          >
            <v-col>
              <h4
                class="c-black fw-600 fs-20"
                data-cy="selection-name"
                dense
              >
                {{ $t(provider.name) }}
              </h4>
              <div
                v-if="provider.license_business_name != provider.name"
                class="fs-16"
              >
                {{ provider.license_business_name }}
              </div>
            </v-col>
          </v-row>

          <v-row
            class="ma-0 pa-0"
            dense
          >
            <v-col>
              <div class="fs-16 fw-400">
                {{ providerAddress() }}
              </div>
            </v-col>
          </v-row>

          <FavoriteProviderSummary
            v-if="$store.state.schemas[provider.schema_id].meta.card.enable_summary"
            :provider="provider"
          />

          <div v-if="$role == 'specialist'">
            <div
              v-for="(opening, openingsIndex) in openings"
              v-show="opening.count > 0"
              :key="openingsIndex"
              class="align-center b-1 bc-extra-light-gray d-flex my-3 pa-4"
            >
              <v-icon
                class="c-green me-2"
                size="24"
              >
                check_circle
              </v-icon>
              <div class="d-inline-block fs-16 fw-500">
                {{ $t('Openings available for') }}
              </div>
              <div class="d-inline-block fs-16 fw-500 ms-1 fw-600">
                {{ monthsToAgeString(opening.min) }} - {{ monthsToAgeString(opening.max) }}
              </div>
              <v-spacer />
              <v-btn
                @click="reserve(opening)"
                color="primary"
              >
                {{ $t('Match') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import FavoriteProviderSummary from '@/parent/components/favorites/FavoriteProviderSummary.vue';
import { formatAddress } from '@/shared/services/address';
import SelectionRanking from '@/shared/components/subsidy/SelectionRanking.vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  hideToggle: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: null,
  },
  length: {
    type: Number,
    default: null,
  },
  match: {
    type: Object,
    default: null,
  },
  ordered: {
    type: Boolean,
    default: false,
  },
  providerId: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['move', 'toggle']);

const openings = ref([]);
const provider = ref(null);

onMounted(load);

function load() {
  if (!props.match && props.providerId) {
    Api.public_api.provider.get(props.providerId, (resp) => {
      provider.value = resp.data;
      openings.value = provider.value.vacancies;
    });
  } else {
    provider.value = props.match;
    openings.value = props.match.openings;
  }
}

function providerAddress() {
  if (!provider.value) return null;

  return formatAddress(provider.value.address1, provider.value.city, provider.value.zip);
}
</script>
