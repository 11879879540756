<!-- eslint-disable max-len -->
<template>
  <v-container class="mxw-800 mx-auto py-12">
    <SubsidyProgramValidation :subsidy-program="subsidyProgram">
      <template #open>
        <span class="c-primary font-weight-bold px-7">
          {{ renderText(subsidyProgram?.name) }}
        </span>
        <div
          ref="progress"
          :aria-label="$t('Progress indicator - question') + ' ' + stepCount"
          class="px-7 focus-invisible"
          role="progressbar"
          tabindex="0"
          aria-live
        >
          <v-progress-linear
            v-if="!progressUnknown"
            v-model="progress"
            :indeterminate="!loaded"
            class="focus-invisible mb-4"
            color="primary"
          />
        </div>

        <div
          v-if="loaded"
          class="px-md-4"
        >
          <transition-group :name="transitionName">
            <FormQuestion
              v-show="section == 'group'"
              @next="validateAddress()"
              :key="'group'"
              :next-disabled="!validHomeAddress"
              :processing="processing"
              :reversible="false"
              :subtitle="
                subsidyProgram.home_address_subtitle ||
                'Let\'s start with your family\'s primary home address.'
              "
              :title="
                subsidyProgram.home_address_title ||
                'Welcome! We\'ll make it fast and easy to apply.'
              "
            >
              <template #preamble>
                <p>
                  <span class="c-red me-1">*</span>
                  <span
                    v-t="'Indicates a required field'"
                    class="c-light-black"
                  />
                </p>
              </template>

              <v-row dense>
                <LabeledTextfield
                  v-model="group.home_address"
                  data-cy="address"
                  message="Address"
                  mandatory
                />
                <template v-if="subsidyProgram.city_options.length > 0">
                  <LabeledSimpleSelect
                    v-model="group.home_city"
                    :items="subsidyProgram.city_options"
                    data-cy="city-input"
                    md="4"
                    message="City"
                    mandatory
                  />
                </template>
                <template v-else>
                  <LabeledTextfield
                    v-model="group.home_city"
                    data-cy="city"
                    md="4"
                    message="City"
                    mandatory
                  />
                </template>
                <LabeledSimpleSelect
                  v-model="group.home_state"
                  :items="stateOptions"
                  data-cy="state-input"
                  md="4"
                  message="State"
                  mandatory
                />
                <template v-if="subsidyProgram.zip_options.length > 0">
                  <LabeledSimpleSelect
                    v-model="group.home_zip"
                    :items="subsidyProgram.zip_options"
                    data-cy="zip-input"
                    md="4"
                    message="Zip"
                    mandatory
                  />
                </template>
                <template v-else>
                  <LabeledTextfield
                    v-model="group.home_zip"
                    data-cy="zip"
                    mask="#####"
                    md="4"
                    message="Zip"
                    mandatory
                  />
                </template>
              </v-row>
              <v-row
                v-if="subsidyProgram.enable_homeless_address_option"
                dense
              >
                <v-checkbox
                  v-model="group.experiencing_homelessness"
                  :label="
                    $t(
                      subsidyProgram.homeless_attestation ||
                        'My family is experiencing homelessness.',
                    )
                  "
                />
              </v-row>
            </FormQuestion>
          </transition-group>

          <QuestionSet
            v-model="group"
            @back="backFromGroupEligibilityQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromGroupEligibilityQuestion($event)"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :processing="processing"
            :questions="validGroupEligibilityQuestions"
            :schema="groupSchema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="GroupEligibility"
          />

          <QuestionSet
            v-if="applicantEligibilityQuestions[localChild.id]"
            v-model="localChild"
            @back="backFromChildEligibilityQuestion($event)"
            @change="maybeClearPreferences(localChild, $event)"
            @change:attachments="loadAttachments()"
            @input="localChild = $event"
            @next="forwardFromChildEligibilityQuestion($event)"
            key="child-eligibility"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachment-tags-supplements="[subsidy.id]"
            :attachments="attachments"
            :header="$t('Child') + ' - ' + localChild.first_name"
            :processing="processing"
            :questions="
              applicantEligibilityQuestions[localChild.id].filter((question) => question.valid)
            "
            :schema="childSchema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="ChildEligibility"
          />

          <transition-group :name="transitionName">
            <template v-if="subsidyProgram.eligibility && subsidy && section == 'eligibility'">
              <FormQuestion
                @back="backFromEligibility"
                @finish="exit"
                @next="forwardFromEligibility"
                key="eligibility"
                :finishable="!subsidy.projected_eligibility"
                :forwardable="subsidy.projected_eligibility"
                :processing="processing"
                :subtitle="
                  subsidyProgram.eligibility_subtitle ||
                  'Based on the answers you supplied, we\'ve determined your eligibility. If you think this determination is incorrect, please message our support team.'
                "
                :title="subsidyProgram.eligibility_title || 'Here is your eligibility report:'"
                class="focus-very-visible"
                finish-text="Exit"
                next-text="Continue application"
                next-arrow
              >
                <div
                  v-if="subsidy.projected_eligibility"
                  class="mb-8"
                >
                  <SubsidyEligibilityCard
                    :key="subsidy.id"
                    :elevation="2"
                    :funding-sources="subsidy.meta.funding_sources"
                    :name="localChild.name"
                    :projected-eligibility="subsidy.projected_eligibility"
                    outlined
                  />
                </div>

                <div v-if="!subsidy.projected_eligibility">
                  <div
                    v-t="'Child is not eligible:'"
                    class="fs-20 fw-500 mb-4"
                  />
                  <SubsidyEligibilityCard
                    :key="subsidy.id"
                    :elevation="2"
                    :funding-sources="subsidy.meta.funding_sources"
                    :name="localChild.name"
                    :projected-eligibility="subsidy.projected_eligibility"
                    outlined
                  />
                </div>

                <v-alert
                  v-if="!subsidy.projected_eligibility"
                  border="start"
                  class="w-100pc mt-6 fs-20"
                  color="primary"
                  type="info"
                >
                  <div>
                    <div
                      v-t="
                        `Your child is not eligible for this ${terms.program.toLowerCase()} at this time. Please contact support if you believe this is incorrect.`
                      "
                    />
                  </div>
                </v-alert>
              </FormQuestion>
            </template>
          </transition-group>

          <transition-group :name="transitionName">
            <ThirdPartyQuestions
              v-if="section == 'third-party-questions'"
              @back="backFromThirdPartyQuestions"
              @finish="exit"
              @next="forwardFromThirdPartyQuestions"
              key="third-party-questions"
              :model-value="subsidy"
              :next-disabled="thirdPartyContinueDisabled()"
              :processing="processing"
              :question-class="'focus-very-visible'"
              finish-text="Exit"
              next-text="Continue"
              forwardable
            />
          </transition-group>

          <QuestionSet
            v-model="group"
            @back="backFromGroupQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromGroupQuestion($event)"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachments="attachments"
            :processing="processing"
            :questions="validGroupQuestions"
            :schema="groupSchema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="Group"
          />

          <QuestionSet
            v-if="validApplicantQuestions[localChild.id]"
            v-model="localChild"
            @back="backFromChildQuestion($event)"
            @change:attachments="loadAttachments()"
            @input="localChild = $event"
            @next="forwardFromChildQuestion($event)"
            key="child"
            :attachment-group-id="group.id"
            :attachment-owner-id="subsidyProgram.id"
            :attachment-owner-type="'SubsidyProgram'"
            :attachment-tags-supplements="[subsidy.id]"
            :attachments="attachments"
            :header="$t('Child') + ' - ' + localChild.first_name"
            :processing="processing"
            :questions="validApplicantQuestions[localChild.id]"
            :schema="childSchema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="Child"
          />

          <template
            v-if="
              subsidyProgram.allow_preference ||
              subsidyProgram.allow_program_preference ||
              subsidyProgram.allow_enrolled
            "
          >
            <template v-if="subsidyProgram.enable_advanced_selector">
              <template v-if="subsidyProgram.allow_program_preference">
                <ProgramPreferenceEditor
                  v-show="section == 'favorites'"
                  @back="backFromFavoriteQuestion"
                  @next="forwardFromFavoriteQuestion"
                  @reorder-programs="(from, to) => handleReorderSelectedPrograms(from, to, subsidy)"
                  @selected-current-provider-id="handleSelectedCurrentProvider"
                  @selected-program-ids="(obj) => handleSelectedPrograms(obj, subsidy)"
                  @selected-sibling-ids="handleSelectedSiblings"
                  @selected-staff-ids="handleSelectedStaff"
                  :child-data="localChild"
                  :current-provider="subsidy.current_provider_id"
                  :default-query="homeAddress"
                  :processing="processing"
                  :sibling-providers="subsidy.sibling_provider_ids"
                  :staff-providers="subsidy.staff_provider_ids"
                  :subsidy="subsidy"
                  :subsidy-program="subsidyProgram"
                />
              </template>
              <template v-else>
                <ProviderPreferenceEditor
                  v-show="section == 'favorites'"
                  @back="backFromFavoriteQuestion"
                  @next="forwardFromFavoriteQuestion"
                  :child-data="localChild"
                  :default-query="homeAddress"
                  :processing="processing"
                  :subsidy="subsidy"
                  :subsidy-program="subsidyProgram"
                />
              </template>
            </template>
            <template v-else>
              <template v-if="subsidyProgram.allow_preference">
                <FormQuestion
                  v-show="section == 'favorites'"
                  @back="backFromFavoriteQuestion"
                  @next="forwardFromFavoriteQuestion"
                  key="favorites"
                  :header="$t('Child') + ' - ' + localChild.first_name"
                  :next-disabled="subsidy.provider_ids.length == 0"
                  :processing="processing"
                  :subtitle="
                    subsidyProgram.preference_subtitle ||
                    (subsidyProgram.allow_preference_order
                      ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                      : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                  "
                  :title="
                    subsidyProgram.preference_title ||
                    (subsidyProgram.allow_preference_order
                      ? 'Select and rank the providers you’d like to include in the application for this child'
                      : 'Select the providers you’d like to include in the application for this child')
                  "
                >
                  <FavoritesSelector
                    :child-data="localChild"
                    :default-query="homeAddress"
                    :ordered="subsidyProgram.allow_preference_order"
                    :subsidy="subsidy"
                    :subsidy-program="subsidyProgram"
                    class="mt-4 mb-4"
                  />
                </FormQuestion>
              </template>

              <template v-if="subsidyProgram.allow_enrolled">
                <FormQuestion
                  v-show="section == 'favorites'"
                  @back="backFromFavoriteQuestion"
                  @next="forwardFromFavoriteQuestion"
                  key="favorites"
                  :header="$t('Child') + ' - ' + localChild.first_name"
                  :processing="processing"
                  :subtitle="subsidyProgram.preference_subtitle"
                  :title="
                    subsidyProgram.preference_title ||
                    'If your child is already enrolled in care, add the provider below.'
                  "
                >
                  <ProviderSelector
                    :child="localChild"
                    :subsidy="subsidy"
                  />
                </FormQuestion>
              </template>
            </template>
          </template>

          <transition-group :name="transitionName">
            <div
              v-for="(question, index) in validGroupVerificationQuestions"
              v-show="section == ['group-verification', index].join('-')"
              :key="['group-verification', index].join('-')"
            >
              <FormQuestion
                @back="backFromGroupVerificationQuestion(index)"
                @next="forwardFromGroupVerificationQuestion(index)"
                :next-disabled="
                  question.verification_mandatory &&
                  attachments.filter((attachment) => attachment.tag == question.id).length == 0
                "
                :processing="processing"
                :schema="{}"
                :subtitle="question.verification_subtitle"
                :title="question.verification_title"
              >
                <AttachmentUploader
                  @uploaded="attachments.push($event)"
                  :ref="['uploader', question.id].join('')"
                  :owner="{
                    type: 'SubsidyProgram',
                    id: subsidyProgram.id,
                    tag: question.id,
                    tags: [subsidy.id, question.id],
                  }"
                  class="mb-4"
                />
                <AttachmentList
                  @delete="loadAttachments"
                  :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                  :processing="processing"
                  class="mb-6"
                />
              </FormQuestion>
            </div>

            <div key="child-verification">
              <transition-group :name="transitionName">
                <div
                  v-for="(question, index) in childVerificationQuestions"
                  v-show="section == ['child-verification', index].join('-')"
                  :key="['child-verification', index].join('-')"
                >
                  <FormQuestion
                    @back="backFromChildVerificationQuestion($event)"
                    @next="forwardFromChildVerificationQuestion(index)"
                    :header="$t('Child') + ' - ' + localChild.first_name"
                    :next-disabled="
                      question.verification_mandatory &&
                      attachments.filter(
                        (attachment) => attachment.tag == localChild.id + question.id,
                      ).length == 0
                    "
                    :processing="processing"
                    :schema="childSchema.definition"
                    :subtitle="question.verification_subtitle"
                    :title="question.verification_title"
                  >
                    <AttachmentUploader
                      @uploaded="attachments.push($event)"
                      :ref="['uploader', question.id, localChild.id].join('')"
                      :owner="{
                        type: 'SubsidyProgram',
                        id: subsidyProgram.id,
                        tag: localChild.id + question.id,
                        tags: [localChild.id, subsidy.id, question.id],
                      }"
                      class="mb-4"
                    />
                    <AttachmentList
                      @delete="loadAttachments"
                      :attachments="
                        attachments.filter(
                          (attachment) => attachment.tag == localChild.id + question.id,
                        )
                      "
                      :processing="processing"
                      class="mb-6"
                    />
                  </FormQuestion>
                </div>
              </transition-group>
            </div>
          </transition-group>

          <transition-group :name="transitionName">
            <template v-if="subsidyProgram.verify_child">
              <FormQuestion
                v-show="section == 'child-documents'"
                @back="backFromChildDocuments"
                @next="forwardFromChildDocuments"
                key="child-documents"
                :header="$t('Child') + ' - ' + localChild.first_name"
                :next-disabled="
                  subsidyProgram.verify_child_mandatory &&
                  attachments.filter(
                    (attachment) => attachment.tag == 'child-documents-' + localChild.id,
                  ).length == 0
                "
                :processing="processing"
                :subtitle="subsidyProgram.verify_child_subtitle"
                :title="subsidyProgram.verify_child_title"
              >
                <p class="fs-15 c-light-black" />
                <AttachmentUploader
                  @uploaded="attachments.push($event)"
                  :owner="{
                    type: 'SubsidyProgram',
                    id: subsidyProgram.id,
                    tag: 'child-documents-' + localChild.id,
                    tags: ['child-documents', localChild.id, subsidy.id],
                  }"
                  class="mb-4"
                />
                <AttachmentList
                  @delete="loadAttachments"
                  :attachments="
                    attachments.filter(
                      (attachment) => attachment.tag == 'child-documents-' + localChild.id,
                    )
                  "
                  :processing="processing"
                />
              </FormQuestion>
            </template>

            <FormQuestion
              v-show="section == 'proof-of-residency'"
              @back="backFromDocuments"
              @next="step_forward('confirm')"
              key="proof-of-residency"
              :next-disabled="
                subsidyProgram.verify_home_address_mandatory &&
                attachments.filter((attachment) => attachment.tag == 'proof-of-residency').length ==
                  0
              "
              :processing="processing"
              :subtitle="
                subsidyProgram.verify_home_address_subtitle ||
                'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
              "
              :title="
                subsidyProgram.verify_home_address_title ||
                'Upload a document to verify your current address'
              "
            >
              <p class="fs-15 c-light-black" />
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                ref="uploader_residency"
                :owner="{
                  type: 'SubsidyProgram',
                  id: subsidyProgram.id,
                  tag: 'proof-of-residency',
                  tags: ['proof-of-residency', subsidy.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="
                  attachments.filter((attachment) => attachment.tag == 'proof-of-residency')
                "
                :processing="processing"
              />
            </FormQuestion>

            <FormQuestion
              v-if="section == 'confirm'"
              @back="backFromConfirm()"
              @finish="finish"
              key="confirm"
              :finish-disabled="!confirmed"
              :forwardable="false"
              :next-disabled="!confirmed"
              :processing="processing"
              :title="subsidyProgram.confirm_title || 'Confirm your application information'"
              header="Last step"
              finishable
            >
              <FormQuestion
                :processing="processing"
                :subtitle="subsidyProgram.home_address_subtitle"
                :title="
                  subsidyProgram.home_address_title ||
                  'What is your family\'s primary home address.'
                "
                class="mb-4"
                dense
                expanded
                hide-actions
                paddingless
              >
                <v-row>
                  <LabeledTextfield
                    v-model="group.home_address"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_address"
                    message="Address"
                    dense
                  />
                  <LabeledTextfield
                    v-model="group.home_city"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_city"
                    md="4"
                    message="City"
                    dense
                  />
                  <LabeledSimpleSelect
                    v-model="group.home_state"
                    :hard-lock="reviewLocked"
                    :items="$a.assets.states"
                    :schema-id="group.schema_id"
                    field="home_state"
                    md="4"
                    message="State"
                    placeholder="Select one"
                    dense
                  />
                  <LabeledTextfield
                    v-model="group.home_zip"
                    :hard-lock="reviewLocked"
                    :schema-id="group.schema_id"
                    field="home_zip"
                    md="4"
                    message="Zip"
                    dense
                  />
                </v-row>
                <v-row
                  v-if="subsidyProgram.enable_homeless_address_option"
                  dense
                >
                  <v-checkbox
                    v-model="group.experiencing_homelessness"
                    :disabled="reviewLocked"
                    :label="
                      $t(
                        subsidyProgram.homeless_attestation ||
                          'My family is experiencing homelessness.',
                      )
                    "
                  />
                </v-row>
              </FormQuestion>

              <v-divider class="mb-4" />

              <FormQuestion
                :key="localChild.id"
                :header="[$t('Child'), localChild.first_name].join(' - ')"
                :processing="processing"
                class="mb-4"
                title="Name and date of birth"
                break-after
                dense
                hide-actions
                paddingless
              >
                <Child
                  :hard-lock="reviewLocked"
                  :initial-child="localChild"
                  dense
                  remove-disabled
                />
              </FormQuestion>

              <QuestionSet
                v-model="group"
                @change:attachments="loadAttachments()"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :processing="processing"
                :questions="validGroupEligibilityQuestions"
                :readonly="reviewLocked"
                :schema="groupSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <QuestionSet
                v-if="applicantEligibilityQuestions[localChild.id]"
                v-model="localChild"
                @change:attachments="loadAttachments()"
                @input="localChild = $event"
                :key="localChild.id + '-eligibility'"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachment-tags-supplements="[subsidy.id]"
                :attachments="attachments"
                :header="$t('Child') + ' - ' + localChild.first_name"
                :processing="processing"
                :questions="
                  applicantEligibilityQuestions[localChild.id].filter((question) => question.valid)
                "
                :readonly="reviewLocked"
                :schema="childSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <QuestionSet
                v-model="group"
                @change:attachments="loadAttachments()"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachments="attachments"
                :processing="processing"
                :questions="validGroupQuestions"
                :readonly="reviewLocked"
                :schema="groupSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <QuestionSet
                v-if="applicantQuestions[localChild.id]"
                v-model="localChild"
                @change:attachments="loadAttachments()"
                @input="localChild = $event"
                :key="localChild.id + '-other'"
                :attachment-group-id="group.id"
                :attachment-owner-id="subsidyProgram.id"
                :attachment-owner-type="'SubsidyProgram'"
                :attachment-tags-supplements="[subsidy.id]"
                :attachments="attachments"
                :processing="processing"
                :questions="applicantQuestions[localChild.id].filter((question) => question.valid)"
                :readonly="reviewLocked"
                :schema="childSchema.definition"
                dense
                divided
                expanded
                hide-actions
                paddingless
              />

              <template v-if="favoriteSelectionEnabled">
                <div key="favorites">
                  <v-divider class="my-4 no-print" />
                  <template v-if="subsidyProgram.enable_advanced_selector">
                    <template v-if="subsidyProgram.allow_program_preference">
                      <ProgramPreferenceReview
                        :child-name="localChild.first_name"
                        :current-provider-id="subsidy.current_provider_id"
                        :program-ids="subsidy.program_ids"
                        :sibling-provider-ids="subsidy.sibling_provider_ids"
                        :staff-provider-ids="subsidy.staff_provider_ids"
                        :subsidy-program="subsidyProgram"
                      />
                    </template>
                    <template v-else>
                      <ProviderPreferenceReview
                        :child-name="localChild.first_name"
                        :current-provider-id="subsidy.current_provider_id"
                        :provider-ids="subsidy.provider_ids"
                        :subsidy-program="subsidyProgram"
                      />
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="subsidyProgram.allow_preference">
                      <FormQuestion
                        :header="$t('Child') + ' - ' + localChild.first_name"
                        :subtitle="
                          subsidyProgram.preference_subtitle ||
                          (subsidyProgram.allow_preference_order
                            ? 'Selecting a provider indicates that you’re interested in receiving care from this provider. Rank your favorites by reordering the selected providers from top-bottom. Top being your top choice. We’ll do our best to match you with a provider of your choice!'
                            : 'Selecting a provider indicates that you’re interested in receiving care from this provider.')
                        "
                        :title="
                          subsidyProgram.preference_title ||
                          (subsidyProgram.allow_preference_order
                            ? 'Select and rank the providers you’d like to include in the application for this child'
                            : 'Select the providers you’d like to include in the application for this child')
                        "
                        dense
                        hide-actions
                        paddingless
                      >
                        <FavoritesSelector
                          :child-data="localChild"
                          :ordered="subsidyProgram.allow_preference_order"
                          :subsidy="subsidy"
                          :subsidy-program="subsidyProgram"
                          class="mt-4 mb-4"
                          initial-mode="selected"
                        />
                      </FormQuestion>
                    </template>

                    <template v-if="subsidyProgram.allow_enrolled">
                      <FormQuestion
                        :header="$t('Child') + ' - ' + localChild.first_name"
                        :subtitle="subsidyProgram.preference_subtitle"
                        :title="
                          subsidyProgram.preference_title ||
                          'If your child is already enrolled in care, add the provider below.'
                        "
                        dense
                        hide-actions
                        paddingless
                      >
                        <ProviderSelector
                          :child="localChild"
                          :subsidy="subsidy"
                        />
                      </FormQuestion>
                    </template>
                  </template>
                </div>
              </template>

              <div
                v-for="question in validGroupVerificationQuestions"
                :key="question.id"
              >
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="question.verification_subtitle"
                  :title="question.verification_title"
                  dense
                  hide-actions
                  paddingless
                >
                  <AttachmentList
                    :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </div>

              <template v-if="applicantVerificationQuestions[localChild.id]">
                <div
                  v-for="question in applicantVerificationQuestions[localChild.id].filter(
                    (questionObject) => questionObject.valid,
                  )"
                  :key="question.id + localChild.id"
                >
                  <v-divider class="my-4" />

                  <FormQuestion
                    :subtitle="question.verification_subtitle"
                    :title="question.verification_title"
                    dense
                    hide-actions
                    paddingless
                  >
                    <AttachmentList
                      :attachments="
                        attachments.filter(
                          (attachment) => attachment.tag == localChild.id + question.id,
                        )
                      "
                      :empty-label="$t('No documents provided.')"
                      :processing="processing"
                      dense
                      hide-remove
                    />
                  </FormQuestion>
                </div>
              </template>

              <template v-if="subsidyProgram.verify_child">
                <div>
                  <v-divider class="my-4" />
                  <FormQuestion
                    :header="$t('Child') + ' - ' + localChild.first_name"
                    :subtitle="subsidyProgram.verify_child_subtitle"
                    :title="subsidyProgram.verify_child_title"
                    dense
                    hide-actions
                    paddingless
                  >
                    <AttachmentList
                      :attachments="
                        attachments.filter(
                          (attachment) => attachment.tag == 'child-documents-' + localChild.id,
                        )
                      "
                      :empty-label="$t('No documents provided.')"
                      :processing="processing"
                      dense
                      hide-remove
                    />
                  </FormQuestion>
                </div>
              </template>

              <template v-if="subsidyProgram.verify_home_address">
                <v-divider class="my-4" />

                <FormQuestion
                  :subtitle="
                    subsidyProgram.verify_home_address_subtitle ||
                    'Ex. A copy of current lease, proof of homeownership, or utility bill (with service or premise address listed) such as your bill for gas, electric, water, or cable.'
                  "
                  :title="
                    subsidyProgram.verify_home_address_title ||
                    'Upload a document to verify your current address'
                  "
                  dense
                  hide-actions
                  paddingless
                >
                  <AttachmentList
                    :attachments="
                      attachments.filter((attachment) => attachment.tag == 'proof-of-residency')
                    "
                    :empty-label="$t('No documents provided.')"
                    :processing="processing"
                    dense
                    hide-remove
                  />
                </FormQuestion>
              </template>

              <v-divider class="my-8" />

              <ThirdPartyQuestions
                v-if="subsidyProgram.enable_third_party_applications"
                key="third-party-questions"
                :model-value="subsidy"
                :processing="processing"
                :readonly="true"
                question-class="focus-very-visible"
              />

              <v-divider class="my-8" />

              <UserAttestation
                v-if="subsidy.third_party_application && subsidyProgram.enable_third_party_esign"
                @change="confirmed = $event"
                ref="attestation"
                :attestation-label="
                  subsidyProgram.third_party_attestation_label[$store.state.profile.default_locale]
                "
                :attestation-text="subsidyProgram.third_party_attestation_text"
                :enable-esign="true"
              />

              <UserAttestation
                v-else
                @change="confirmed = $event"
                ref="attestation"
                :attestation-label="subsidyProgram.attestation_label"
                :attestation-text="subsidyProgram.attestation_text"
                :enable-esign="subsidyProgram.enable_esign"
              />
            </FormQuestion>
          </transition-group>

          <AddressVerificationDialog
            @cancel="forwardFromHome"
            @confirm="updateHomeAddress"
            ref="validateAddressDialog"
            cancel-button-text="Keep original"
            confirm-button-text="Use recommended"
            title="Verify address"
          />
        </div>
      </template>
    </SubsidyProgramValidation>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script>
import { SUBSIDY_EVENTS } from '@/parent/services/constants';
import AddressVerificationDialog from '@/shared/components/AddressVerificationDialog.vue';
import API from '@/shared/mixins/api';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import Child from '@/parent/components/children/Child.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import FavoritesSelector from '@/parent/components/favorites/FavoritesSelector.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ProgramPreferenceEditor from '@/parent/components/ProgramPreferenceEditor.vue';
import ProgramPreferenceReview from '@/parent/components/subsidy/ProgramPreferenceReview.vue';
import ProviderPreferenceEditor from '@/parent/components/ProviderPreferenceEditor.vue';
import ProviderPreferenceReview from '@/parent/components/subsidy/ProviderPreferenceReview.vue';
import ProviderSelector from '@/parent/components/ProviderSelector.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import RenderLiquid from '@/shared/mixins/render_liquid';
import Stepper from '@/shared/mixins/stepper';
import Subsidy from '@/shared/mixins/subsidy';
import SubsidyApplicationData from '@/shared/services/subsidy/application-data.js';
import SubsidyEligibilityCard from '@/shared/components/subsidy/SubsidyEligibilityCard.vue';
import SubsidyProgramValidation from '@/shared/components/SubsidyProgramValidation.vue';
import Terms from '@/shared/mixins/terms';
import ThirdPartyQuestions from '@/parent/components/family_subsidy/ThirdPartyQuestions.vue';
import UserAttestation from '@/shared/components/UserAttestation.vue';

const ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR =
  'Your address could not be validated. Please check for any errors and retry. If you believe this address to be correct, please contact support for assistance.';
const LEAVE_WARNING =
  'You have unsaved changes and have not submitted your application. Are you sure you want to leave your application?';
const NOW_INELIGIBLE_ERROR =
  'Application data has changed that has resulted in your application becoming ineligible. Please review your application or contact support if you believe this is incorrect.';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AddressVerificationDialog,
    AttachmentList,
    AttachmentUploader,
    Child,
    ConfirmDialog,
    FavoritesSelector,
    FormQuestion,
    LabeledSimpleSelect,
    LabeledTextfield,
    ProgramPreferenceEditor,
    ProgramPreferenceReview,
    ProviderPreferenceEditor,
    ProviderPreferenceReview,
    ProviderSelector,
    QuestionSet,
    SubsidyEligibilityCard,
    SubsidyProgramValidation,
    ThirdPartyQuestions,
    UserAttestation,
  },

  mixins: [API, RenderLiquid, Stepper, Subsidy, Terms],

  async beforeRouteLeave(to, from, next) {
    if (!this.finished) {
      const confirm = await this.$refs.confirmDialog.confirmWithText(LEAVE_WARNING);
      if (!confirm) return;
    }

    window.onbeforeunload = null;
    next();
  },

  beforeRouteUpdate(to, from, next) {
    this.load();
    next();
  },

  data() {
    return {
      attachments: [],
      attestation: null,
      children: [],
      confirmed: false,
      finished: false,
      group: null,
      groupSchema: null,
      localChild: null,
      progress: null,
      progressUnknown: !!this.$route.query.section && !this.$route.query.step,
      providers: [],
      providerQuery: null,
      processing: false,
      questions: [],
      reviewLocked: false,
      section: null,
      searchedProviders: [],
      stepCount: 0,
      subsidy: null,
      subsidies: [],
      subsidyProgram: null,
      subsidyProgramClosed: false,
      transitionName: null,
    };
  },

  computed: {
    closed() {
      if (this.subsidy.locked) return true;

      if (this.subsidyProgram && this.subsidyProgram.closes_at) {
        return new Date() >= new Date(this.subsidyProgram.closes_at);
      }
      return false;
    },

    favoriteSelectionEnabled() {
      if (
        this.subsidyProgram.enable_iep_direct_placement &&
        this.localChild.individualized_education_plan
      ) {
        return false;
      }

      return (
        this.subsidyProgram.allow_preference ||
        this.subsidyProgram.allow_program_preference ||
        this.subsidyProgram.allow_enrolled
      );
    },

    homeAddress() {
      if (!this.group || this.group?.experiencing_homelessness === true) return null;

      return [
        this.group.home_address,
        this.group.home_city,
        this.group.home_state,
        this.group.home_zip,
      ].join(', ');
    },

    loaded() {
      return !!(
        this.localChild &&
        this.group &&
        this.subsidy &&
        this.subsidyProgram &&
        this.groupSchema &&
        this.childSchema
      );
    },

    stateOptions() {
      if (this.subsidyProgram.state_options.length > 0) {
        return this.$a.assets.states.filter((state) =>
          this.subsidyProgram.state_options.includes(state.value),
        );
      }
      return this.$a.assets.states;
    },

    validHomeAddress() {
      if (!this.group) return false;
      return (
        this.group.experiencing_homelessness ||
        (this.group.home_address &&
          this.group.home_city &&
          this.group.home_state &&
          this.group.home_zip &&
          this.group.home_zip.length >= 5)
      );
    },
  },

  watch: {
    loaded: {
      immediate: true,
      async handler(newVal, oldVal) {
        if (this.subsidyProgram?.state_options?.length === 1 && !this.group.home_state) {
          [this.group.home_state] = this.subsidyProgram.state_options;
        }
        if (this.subsidyProgram?.city_options?.length === 1 && !this.group.home_city) {
          [this.group.home_city] = this.subsidyProgram.city_options;
        }
        if (newVal && newVal !== oldVal) {
          this.stepCount = parseInt(this.$route.query.step, 10);
          await this.validate();
          this.calculateStepTotal();
          if (this.$route.query.section) {
            setTimeout(() => {
              this.section = this.$route.query.section;
              this.$refs.progress?.focus();
              this.progress = (this.stepCount / this.stepTotal) * 100;
              this.$forceUpdate();
            }, 600);
          } else {
            this.$router.push({ query: { section: 'group', step: 1 } });
          }
        }
      },
    },

    '$route.query.section': {
      async handler(newVal) {
        this.section = null;
        this.stepCount = parseInt(this.$route.query.step, 10);
        if (!this.stepTotal) {
          await this.validate();
        }
        this.calculateStepTotal();
        setTimeout(() => {
          if (newVal) {
            this.section = newVal;
            this.$refs.progress?.focus();
          } else {
            this.section = 'group';
          }
          this.progress = (this.stepCount / this.stepTotal) * 100;
          this.$forceUpdate();
        }, 600);
      },
    },

    providerQuery(val) {
      if (val) {
        this.searchProviders();
      }
    },
  },

  created() {
    this.load();

    window.onbeforeunload = async () => {
      await this.$refs.confirmDialog.confirmWithText('You have unsaved information.');
    };
  },

  methods: {
    handleSelectedPrograms({ type, id }, subsidy) {
      switch (type) {
        case SUBSIDY_EVENTS.ADD: {
          return this.subsidy.program_ids.push(id);
        }
        case SUBSIDY_EVENTS.REMOVE:
          return subsidy.program_ids.splice(
            subsidy.program_ids.findIndex((programId) => programId === id),
            1,
          );
        default:
          return null;
      }
    },

    handleReorderSelectedPrograms(fromIndex, toIndex, subsidy) {
      const reorderedProgramId = subsidy.program_ids[fromIndex];

      subsidy.program_ids.splice(fromIndex, 1);
      subsidy.program_ids.splice(toIndex, 0, reorderedProgramId);
    },

    handleSelectedSiblings(ids) {
      this.subsidy.sibling_provider_ids = ids;
    },

    handleSelectedStaff(ids) {
      this.subsidy.staff_provider_ids = ids;
    },

    handleSelectedCurrentProvider(id) {
      this.subsidy.current_provider_id = id;
    },

    async backFromEligibility() {
      this.processing = true;

      if (
        this.childEligibilityQuestions.length > 0 &&
        this.applicantEligibilityQuestions[this.localChild.id].filter((question) => question.valid)
          .length > 0
      ) {
        this.step_back(
          `ChildEligibility-${this.applicantEligibilityQuestions[this.localChild.id].filter((question) => question.valid).length - 1}`,
        );
      } else if (this.validGroupEligibilityQuestions.length > 0) {
        this.step_back(`GroupEligibility-${this.validGroupEligibilityQuestions.length - 1}`);
      } else {
        this.step_back('group');
      }
    },

    async backFromGroupEligibilityQuestion(index) {
      this.processing = true;
      await this.validate();
      await this.saveGroup();

      if (index - 1 < 0) {
        this.step_back('group');
      } else {
        this.step_back(`GroupEligibility-${index - 1}`);
      }
    },

    async backFromGroupQuestion(index) {
      this.processing = true;
      let revisedIndex = index;

      if (revisedIndex === false) {
        revisedIndex = this.validGroupQuestions.length;
      } else {
        await this.validate();
        await this.saveGroup();
      }

      if (revisedIndex - 1 < 0) {
        if (this.subsidyProgram.enable_third_party_applications) {
          return this.step_back('third-party-questions');
        }
        if (this.subsidies.length > 0 && this.subsidyProgram.eligibility) {
          return this.step_back('eligibility');
        }
        return this.backFromEligibility();
      }
      return this.step_back(`Group-${revisedIndex - 1}`);
    },

    backFromConfirm() {
      this.processing = true;
      if (this.subsidyProgram.verify_home_address) {
        this.step_back('proof-of-residency');
      } else {
        this.backFromDocuments();
      }
    },

    backFromGroupVerificationQuestion(index) {
      this.processing = true;
      let revisedIndex = index;

      if (revisedIndex === false) {
        revisedIndex = this.validGroupVerificationQuestions.length;
      }

      if (index - 1 < 0) {
        this.backFromFavoriteQuestion(false);
      } else {
        this.step_back(`group-verification-${revisedIndex - 1}`);
      }
    },

    async backFromChildEligibilityQuestion(questionIndex) {
      this.processing = true;
      await this.saveChild();
      await this.validate();

      if (questionIndex - 1 >= 0) {
        this.step_back(`ChildEligibility-${questionIndex - 1}`);
      } else if (this.validGroupEligibilityQuestions.length > 0) {
        this.step_back(`GroupEligibility-${this.validGroupEligibilityQuestions.length - 1}`);
      } else {
        this.step_back('group');
      }
    },

    async backFromChildQuestion(questionIndex) {
      this.processing = true;
      let revisedQuestionIndex = questionIndex;

      if (questionIndex === false) {
        if (
          this.applicantQuestions[this.localChild.id].filter((question) => question.valid)
            .length === 0
        ) {
          this.backFromGroupQuestion(false);
          return;
        }
        revisedQuestionIndex = this.applicantQuestions[this.localChild.id].filter(
          (question) => question.valid,
        ).length;
      } else {
        await this.saveChild();
        await this.validate();
      }

      if (revisedQuestionIndex - 1 >= 0) {
        this.step_back(`Child-${revisedQuestionIndex - 1}`);
      } else {
        this.backFromGroupQuestion(false);
      }
    },

    backFromFavoriteQuestion(currentlyViewing = true) {
      this.processing = true;
      if (!currentlyViewing && this.favoriteSelectionEnabled) {
        this.step_back('favorites');
      } else {
        this.backFromChildQuestion(false);
      }
    },

    backFromChildVerificationQuestion(questionIndex) {
      this.processing = true;
      if (questionIndex - 1 >= 0) {
        this.step_back(`child-verification-${questionIndex - 1}`);
      } else {
        this.backFromGroupVerificationQuestion(false);
      }
    },

    async backFromChildDocuments() {
      this.processing = true;
      this.backFromChildVerificationQuestion(
        this.applicantVerificationQuestions[this.localChild.id].length,
      );
    },

    async backFromDocuments() {
      this.processing = true;
      if (this.subsidyProgram.verify_child) {
        this.step_back('child-documents');
      } else {
        this.backFromChildDocuments();
      }
    },

    async backFromThirdPartyQuestions() {
      this.processing = true;

      if (this.subsidies.length > 0 && this.subsidyProgram.eligibility) {
        this.step_back('eligibility');
      } else {
        this.backFromEligibility();
      }
    },

    calculateStepTotal() {
      if (!this.subsidyProgram) return;

      this.stepTotal =
        2 +
        (this.subsidyProgram.eligibility ? 1 : 0) +
        (this.subsidyProgram.verify_home_address ? 1 : 0) +
        (this.subsidyProgram.verify_child ? 1 : 0) +
        (this.subsidyProgram.allow_preference ? 1 : 0) +
        (this.subsidyProgram.allow_program_preference ? 1 : 0) +
        (this.subsidyProgram.allow_enrolled ? 1 : 0) +
        (this.subsidyProgram.enable_third_party_applications ? 1 : 0) +
        this.validGroupEligibilityQuestions.length +
        this.validGroupQuestions.length +
        this.validGroupVerificationQuestions.length +
        (this.validApplicantEligibilityQuestionsLength +
          this.validApplicantQuestionsLength +
          this.validApplicantVerificationQuestionsLength);
    },

    thirdPartyContinueDisabled() {
      if (
        this.subsidy.third_party_application === false ||
        (this.subsidy.third_party_application &&
          this.subsidy.third_party_email &&
          this.subsidy.third_party_first_name &&
          this.subsidy.third_party_last_name &&
          this.subsidy.third_party_phone_number)
      ) {
        return false;
      }

      return true;
    },

    async exit() {
      this.processing = true;
      if (this.subsidyProgram.enable_submit_signout) {
        await this.api.member.session.promiseDestroy();
        this.$store.commit('setAuthenticated', false);
        this.$store.commit('setProfile', null);
        if (this.subsidyProgram.submit_title) {
          this.$eventBus.$emit('longChime', 'You have been automatically logged out for security.');
        }
        this.$router.push({ path: '/' });
      } else {
        this.$router.push({ name: 'Dashboard' });
      }
    },

    async finish() {
      this.processing = true;
      if (!this.subsidyProgram.enable_parent_review_locked) {
        await this.saveGroup();
        await this.saveChild();
      }

      const { data } = await this.api.subsidy.get(this.subsidy.id);
      if (data.projected_eligibility === false) {
        this.$eventBus.$emit('longChime', NOW_INELIGIBLE_ERROR);
        this.stepCount = 0;
        this.transitionName = 'slide-right';
        this.step('group');
        return;
      }

      if (this.subsidyProgram.enable_esign || this.subsidyProgram.enable_third_party_esign) {
        this.subsidy.revision_author_signed_name = this.$refs.attestation.esignName;
        this.subsidy.revision_author_signature_image = this.$refs.attestation.getEsignSignature();
      }

      const submitResp = await this.api.subsidy.update(this.subsidy.id, {
        revision_author_signed_name: this.subsidy.revision_author_signed_name,
        revision_author_signature_image: this.subsidy.revision_author_signature_image,
        submitted: true,
        program_ids: this.subsidy.program_ids,
        provider_ids: this.subsidy.provider_ids,
        sibling_provider_ids: this.subsidy.sibling_provider_ids,
        staff_provider_ids: this.subsidy.staff_provider_ids,
      });
      this.processing = false;
      if (submitResp?.status != 200) return;

      this.finished = true;

      if (this.subsidyProgram.enable_submit_signout) {
        await this.api.member.session.promiseDestroy();
        this.$store.commit('setAuthenticated', false);
        this.$store.commit('setProfile', null);
        if (this.subsidyProgram.submit_title) {
          this.$eventBus.$emit('longChime', this.subsidyProgram.submit_title);
        }
        this.$router.push({ path: '/' });
      } else {
        if (this.subsidyProgram.submit_title) {
          this.$eventBus.$emit('longChime', this.subsidyProgram.submit_title);
        }
        this.$router.push({
          name: 'Dashboard',
          query: { submittedSurveySchemaId: this.subsidyProgram.submitted_survey_schema_id },
        });
      }
    },

    async forwardFromEligibility() {
      this.processing = true;
      await this.validate();

      if (this.subsidyProgram.enable_third_party_applications) {
        return this.step_forward('third-party-questions');
      }
      if (this.validGroupQuestions.length > 0) {
        return this.step_forward('Group-0');
      }
      return this.forwardFromGroupQuestion(1000);
    },

    async forwardFromGroupEligibilityQuestion(index) {
      this.processing = true;
      await this.saveGroup();
      await this.loadSubsidy();
      await this.validate();

      if (index + 1 >= this.validGroupEligibilityQuestions.length) {
        this.forwardFromChildEligibilityQuestion(false);
      } else {
        this.step_forward(`GroupEligibility-${index + 1}`);
      }
    },

    async forwardFromGroupQuestion(index) {
      this.processing = true;
      await this.saveGroup();
      await this.validate();

      if (index + 1 >= this.validGroupQuestions.length) {
        this.forwardFromChildQuestion(-1);
      } else {
        this.step_forward(`Group-${index + 1}`);
      }
    },

    forwardFromGroupVerificationQuestion(index) {
      this.processing = true;

      if (index + 1 >= this.validGroupVerificationQuestions.length) {
        if (this.childVerificationQuestions.length > 0) {
          this.step_forward('child-verification-0');
        } else {
          this.forwardFromChildDocuments();
        }
      } else {
        this.step_forward(`group-verification-${index + 1}`);
      }
    },

    async forwardFromChildEligibilityQuestion(questionIndex) {
      this.processing = true;
      let revisedQuestionIndex = questionIndex;

      await this.saveChild();
      await this.validate();

      if (revisedQuestionIndex === false) {
        if (
          this.applicantEligibilityQuestions[this.localChild.id].filter(
            (question) => question.valid,
          ).length > 0
        ) {
          revisedQuestionIndex = -1;
        } else {
          if (this.subsidyProgram.eligibility) {
            await this.loadSubsidy();
            this.step_forward('eligibility');
            return;
          }
          this.forwardFromEligibility();
          return;
        }
      }

      if (
        revisedQuestionIndex + 1 >=
        this.applicantEligibilityQuestions[this.localChild.id].filter((question) => question.valid)
          .length
      ) {
        if (this.subsidyProgram.eligibility) {
          await this.loadSubsidy();
          this.step_forward('eligibility');
        } else {
          this.forwardFromEligibility();
        }
      } else {
        this.step_forward(`ChildEligibility-${revisedQuestionIndex + 1}`);
      }
    },

    async forwardFromChildQuestion(questionIndex) {
      this.processing = true;
      await this.saveChild();
      await this.validate();

      if (
        questionIndex + 1 >=
        this.applicantQuestions[this.localChild.id].filter((question) => question.valid).length
      ) {
        if (this.favoriteSelectionEnabled) {
          this.step_forward('favorites');
        } else {
          this.forwardFromFavoriteQuestion();
        }
      } else {
        this.step_forward(`Child-${questionIndex + 1}`);
      }
    },

    async forwardFromFavoriteQuestion() {
      this.processing = true;
      await this.validate();
      await this.api.subsidy.update(this.subsidy.id, {
        current_provider_id: this.subsidy.current_provider_id,
        program_ids: this.subsidy.program_ids,
        provider_ids: this.subsidy.provider_ids,
        sibling_provider_ids: this.subsidy.sibling_provider_ids,
        staff_provider_ids: this.subsidy.staff_provider_ids,
      });
      if (this.validGroupVerificationQuestions.length > 0) {
        this.step_forward('group-verification-0');
      } else if (
        this.applicantVerificationQuestions[this.localChild.id].filter((question) => question.valid)
          .length > 0
      ) {
        this.step_forward('child-verification-0');
      } else {
        this.forwardFromChildDocuments();
      }
    },

    forwardFromChildVerificationQuestion(questionIndex) {
      this.processing = true;

      if (questionIndex + 1 >= this.childVerificationQuestions.length) {
        if (this.subsidyProgram.verify_child) {
          this.step_forward('child-documents');
        } else {
          this.forwardFromChildDocuments();
        }
      } else {
        this.step_forward(`child-verification-${questionIndex + 1}`);
      }
    },

    forwardFromChildDocuments() {
      this.processing = true;
      if (this.subsidyProgram.verify_home_address) {
        this.step_forward('proof-of-residency');
      } else {
        this.step_forward('confirm');
      }
    },

    async forwardFromHome() {
      this.processing = true;
      await this.saveGroup();
      await this.validate();
      await this.loadSubsidy();

      this.calculateStepTotal();

      if (this.validGroupEligibilityQuestions.length > 0) {
        this.step_forward('GroupEligibility-0');
      } else if (this.childEligibilityQuestions.length > 0) {
        this.step_forward('ChildEligibility-0');
      } else if (this.subsidyProgram.eligibility) {
        this.step_forward('eligibility');
      } else {
        this.forwardFromEligibility();
      }
    },

    async forwardFromThirdPartyQuestions() {
      this.processing = true;
      const resp = await this.api.subsidy.update(this.subsidy.id, {
        third_party_application: this.subsidy.third_party_application,
        third_party_email: this.subsidy.third_party_email,
        third_party_first_name: this.subsidy.third_party_first_name,
        third_party_last_name: this.subsidy.third_party_last_name,
        third_party_phone_number: this.subsidy.third_party_phone_number,
      });
      if (resp?.status !== 200) {
        this.processing = false;
        return;
      }

      await this.validate();
      if (this.validGroupQuestions.length > 0) {
        this.step_forward('Group-0');
      } else {
        this.forwardFromGroupQuestion(1000);
      }
    },

    load() {
      this.api.subsidy.get(this.$route.params.subsidyId, (subsidyResp) => {
        this.subsidy = subsidyResp.data;
        this.subsidies[0] = this.subsidy;
        this.subsidyProgramId = this.subsidy.subsidy_program_id;

        this.loadChild();
        this.loadGroup();
        this.loadSubsidyProgram();
        this.loadQuestions();
        this.loadAttachments();
        this.providers = this.subsidy.provider_ids;
      });
    },

    async loadChild() {
      const resp = await SubsidyApplicationData.getChildData(this.subsidy.id);
      this.localChild = resp.data;
      this.children = [this.localChild];
    },

    async loadGroup() {
      const resp = await SubsidyApplicationData.getGroupData(this.subsidy.id);
      this.group = resp.data;
    },

    async loadSubsidy() {
      await this.api.subsidy.get(this.$route.params.subsidyId, (resp) => {
        this.subsidy = resp.data;
        this.subsidies[0] = this.subsidy;
      });
    },

    loadSubsidyProgram() {
      this.api.public_api.organization.subsidy_program.get(this.subsidyProgramId, (resp) => {
        this.subsidyProgram = resp.data;
        this.reviewLocked = this.subsidyProgram.enable_parent_review_locked;

        this.loadSchemas();
        document.title = `Continue application - ${this.subsidyProgram.name}`;
      });
    },

    async loadAttachments() {
      this.processing = true;
      const params = {
        owner_type: 'SubsidyProgram',
        owner_id: this.subsidy.subsidy_program_id,
      };

      const resp = await this.api.member.attachment.index(params);
      this.processing = false;
      if (resp?.status === 200) this.attachments = resp.data;
    },

    async maybeClearPreferences(child, event) {
      if (!this.subsidyProgram.enable_iep_direct_placement) return;

      if (
        child.individualized_education_plan === false &&
        event?.attribute?.name === 'individualized_education_plan' &&
        event?.value === true
      ) {
        const subsidy = this.subsidies.find((subsidyItem) => subsidyItem.child_id === child.id);

        subsidy.current_provider_id = null;
        subsidy.program_ids = [];
        subsidy.provider_ids = [];
        subsidy.sibling_provider_ids = [];
        subsidy.staff_provider_ids = [];

        await this.api.subsidy.update(subsidy.id, {
          current_provider_id: subsidy.current_provider_id,
          program_ids: subsidy.program_ids,
          provider_ids: subsidy.provider_ids,
          sibling_provider_ids: subsidy.sibling_provider_ids,
          staff_provider_ids: subsidy.staff_provider_ids,
        });
      }
    },

    async saveGroup() {
      const resp = await SubsidyApplicationData.updateGroupData(this.subsidy.id, this.group);
      this.group = resp.data;
    },

    async saveChild() {
      const resp = await SubsidyApplicationData.updateChildData(this.subsidy.id, this.localChild);
      this.localChild = resp.data;
    },

    searchProviders() {
      this.api.public_api.provider.index({ query: this.providerQuery }, (resp) => {
        this.searchedProviders = resp.data;
      });
    },

    setProvider(provider) {
      if (provider) {
        this.subsidy.provider_ids = [provider.id];
      } else {
        this.subsidy.provider_ids = [];
      }
    },

    toggleInclusion(index, newVal) {
      if (newVal) {
        this.subsidy.provider_ids.push(this.subsidy.excluded_provider_ids.splice(index, 1)[0]);
      } else {
        this.subsidy.excluded_provider_ids.push(this.subsidy.provider_ids.splice(index, 1)[0]);
      }
    },

    updateHomeAddress(uspsData) {
      const address = uspsData.standardizedAddress;
      this.group.home_address = address.firstAddressLine;
      this.group.home_city = address.city;
      this.group.home_state = address.state;
      this.group.home_zip = address.zipCode;
      this.group.home_latitude = uspsData.location.latitude;
      this.group.home_longitude = uspsData.location.longitude;
      this.forwardFromHome();
    },

    async validateAddress() {
      if (
        this.group.experiencing_homelessness ||
        !this.subsidyProgram.enable_home_address_validation
      ) {
        this.forwardFromHome();
        return;
      }

      const response = await this.api.member.address_validation.validate({
        addressLines: [this.group.home_address],
        administrativeArea: this.group.home_state,
        locality: this.group.home_city,
        postalCode: this.group.home_zip,
      });

      if (!response.data) {
        this.$eventBus.$emit(
          'chime',
          'Unknown error occurred attempting to validate your address.',
        );
        return;
      }

      const { hasUnconfirmedComponents } = response.data.verdict;
      if (hasUnconfirmedComponents) {
        const allSubpremise = response.data.address.addressComponents.every((addressComponent) => {
          if (addressComponent.confirmationLevel === 'CONFIRMED') return true;
          if (addressComponent.componentType === 'subpremise') return true;
          return false;
        });

        if (!allSubpremise) {
          this.$eventBus.$emit('longChime', ADDRESS_HAS_UNCONFIRMED_COMPONENTS_ERROR);
          return;
        }
      }

      const { geocode, uspsData } = response.data;
      if (this.validatedAddressMatches(uspsData.standardizedAddress)) {
        this.group.home_latitude = geocode.location.latitude;
        this.group.home_longitude = geocode.location.longitude;
        this.forwardFromHome();
        return;
      }

      this.$refs.validateAddressDialog.open({ value: this.group, ...uspsData, ...geocode });
    },

    validatedAddressMatches(validated) {
      return (
        this.group.home_zip === validated.zipCode &&
        this.group.home_state.toLowerCase() === validated.state.toLowerCase() &&
        this.group.home_city.toLowerCase() === validated.city.toLowerCase() &&
        this.group.home_address.toLowerCase() === validated.firstAddressLine.toLowerCase()
      );
    },
  },
};
</script>
