<template>
  <div data-testid="dashboard-v1">
    <template v-if="!processing">
      <DashboardIntro
        @add-child="$router.push({ name: 'ChildNew' })"
        :add-child="hasPublishedChildSubsidies()"
        :first-name="$store.state.profile.first_name"
      />

      <DashboardInstructions :schema="groupSchema" />

      <template v-if="familySubsidyPrograms?.length > 0">
        <v-card
          class="mb-4"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              v-for="familySubsidyProgram in familySubsidyPrograms"
              :key="familySubsidyProgram.id"
            >
              <v-col
                v-if="
                  !familySubsidyProgram.enable_invite_only ||
                  hasUnfinishedFamilySubsidies(filteredFamilySubsidies(familySubsidyProgram))
                "
              >
                <FamilySubsidyCard
                  @create="createFamilySubsidy"
                  :family-subsidies="filteredFamilySubsidies(familySubsidyProgram)"
                  :family-subsidy-program="familySubsidyProgram"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>

      <template v-if="children?.length > 0">
        <v-alert
          v-for="childSubsidyProgram in openChildSubsidyPrograms"
          :key="childSubsidyProgram.id"
          :icon="smallView ? '' : 'school'"
          color="primary"
          closable
          prominent
        >
          <v-row class="d-flex align-center">
            <v-col class="d-flex align-center">
              <p class="c-white fs-22 fw-500 mb-0">
                {{ $t(childSubsidyProgram.name) }}
              </p>
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <v-btn
                :to="{
                  name: 'ChildSubsidyNew',
                  params: { subsidyProgramId: childSubsidyProgram.id },
                }"
                class="me-4"
                color="bg-primary"
                size="x-large"
                variant="outlined"
              >
                <span v-t="'Start or continue application'" />
                <v-icon class="ms-2"> east </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <v-row>
          <v-col
            v-for="child in children"
            :key="child.id"
            cols="12"
            lg="6"
            md="6"
            sm="6"
          >
            <ChildCard :child-data="child" />
          </v-col>
        </v-row>
      </template>

      <VerticalSpacer :min-height="50" />
    </template>
  </div>
</template>

<script>
import api from '@/shared/services/bright_finder';
import ChildCard from '@/parent/components/children/ChildCard.vue';
import DashboardIntro from '@/parent/components/dashboards/DashboardIntro.vue';
import DashboardInstructions from '@/parent/components/dashboards/DashboardInstructions.vue';
import FamilySubsidyCard from '@/parent/components/family_subsidy/FamilySubsidyCard.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { openSubsidyPrograms, subsidyProgramIsOpen } from '@/parent/services/subsidies';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ChildCard,
    DashboardIntro,
    DashboardInstructions,
    FamilySubsidyCard,
    VerticalSpacer,
  },

  props: {
    group: {
      type: Object,
      default: null,
    },
    groupSchema: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      children: null,
      familySubsidies: [],
      familySubsidyPrograms: null,
      messages: [],
      newChild: {},
      processing: true,
      subsidies: null,
      childSubsidyPrograms: [],
      surveySchema: null,
      tab: null,
    };
  },

  computed: {
    openChildSubsidyPrograms() {
      return openSubsidyPrograms(this.subsidies, this.childSubsidyPrograms);
    },

    smallView() {
      return window.innerWidth < 400;
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    async createFamilySubsidy(familySubsidyProgram) {
      const resp = await api.parent.family_subsidy.create({
        family_subsidy_program_id: familySubsidyProgram.id,
      });
      this.$router.push({ name: 'FamilySubsidyContinue', params: { id: resp.data.id } });
    },

    filteredFamilySubsidies(familySubsidyProgram) {
      let familySubsidies = [];
      familySubsidies = this.familySubsidies.filter(
        (subsidy) => subsidy.family_subsidy_program_id === familySubsidyProgram.id,
      );
      return familySubsidies;
    },

    hasUnfinishedFamilySubsidies(filteredFamilySubsidies) {
      return filteredFamilySubsidies.some((subsidy) => !subsidy.submitted_at);
    },

    async load() {
      this.processing = true;
      await this.loadFamilySubsidyPrograms();
      await this.loadChildren();

      if (this.children.length === 0 && this.familySubsidyPrograms.length === 0) {
        return this.redirectMember();
      }
      this.$nextTick(() => this.$refs.title?.focus());

      await this.loadChildSubsidyPrograms();
      await this.loadFamilySubsidies();
      await this.loadSubsidies();
      this.processing = false;
      return true;
    },

    async loadChildren() {
      const resp = await api.child.index();
      this.children = resp.data.filter((child) => child.name !== '');
    },

    async loadFamilySubsidies() {
      const resp = await api.parent.family_subsidy.index({});
      this.familySubsidies = resp.data;
    },

    async loadFamilySubsidyPrograms() {
      const resp = await api.public_api.organization.family_subsidy_program.index();
      this.familySubsidyPrograms = resp.data.filter((familySubsidyProgram) =>
        subsidyProgramIsOpen(familySubsidyProgram),
      );
    },

    async loadChildSubsidyPrograms() {
      const resp = await api.public_api.organization.subsidy_program.index();
      this.childSubsidyPrograms = resp.data;
    },

    async loadSubsidies() {
      const resp = await api.subsidy.index({});
      this.subsidies = resp.data;
    },

    hasPublishedChildSubsidies() {
      return Object.values(this.childSubsidyPrograms).some((value) => !!value.published_at);
    },

    redirectMember() {
      if (this.$store.state.pages.Search.enabled && this.$store.state.profile.search_id) {
        this.$router.replace({
          name: 'SearchShow',
          params: { searchId: this.$store.state.profile.search_id },
        });
      } else {
        this.$router.replace({ path: '/' });
      }
    },
  },
};
</script>
