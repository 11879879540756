<template>
  <div>
    <v-divider />

    <v-row
      dense
      no-gutters
    >
      <v-col
        @click="viewSelectedResults = false"
        @keydown.enter="viewSelectedResults = false"
        :class="!viewSelectedResults ? 'elevation-2' : 'elevation-0'"
        class="pointer"
      >
        <div
          v-t="'Search'"
          :class="!viewSelectedResults ? 'bg-primary c-white' : 'bg-white c-black'"
          class="fw-500 py-4 d-flex align-center justify-center"
          data-cy="search-mode-button"
        />
      </v-col>
      <v-col
        @click="viewSelectedResults = true"
        @keydown.enter="viewSelectedResults = true"
        :class="viewSelectedResults ? 'elevation-2' : 'elevation-0'"
        class="pointer"
        cols="6"
      >
        <div
          v-text="$t('Selected') + ' (' + providerIds.length + ')'"
          :class="viewSelectedResults ? 'bg-primary c-white' : 'bg-white c-black'"
          class="fw-500 py-4 d-flex align-center justify-center"
          data-cy="selected-mode-button"
        />
      </v-col>
    </v-row>

    <v-divider />

    <div class="bg-super-light-blue px-4">
      <div
        v-show="viewSelectedResults"
        class="py-3"
      >
        <div
          v-for="providerId in providerIds"
          :key="providerId"
          class="mb-6"
        >
          <SimpleProviderCard
            @open="$emit('open', providerId)"
            @toggle="$emit('toggle', [providerId, $event])"
            :active="providerIds.includes(providerId)"
            :api="api"
            :provider-id="providerId"
            :selectable="belowSelectionLimit"
            class="my-4"
          />
        </div>
      </div>

      <div
        v-show="!viewSelectedResults"
        class="py-2"
      >
        <v-tabs
          v-model="searchTab"
          @update:model-value="resetResults"
          bg-color="transparent"
          class="mb-2"
        >
          <v-tab>
            <span
              v-t="'Search by address'"
              class="ls-normal tt-none fs-16 fw-500"
              data-cy="address-mode-button"
            />
          </v-tab>
          <v-tab>
            <span
              v-t="'Search by name'"
              class="ls-normal tt-none fs-16 fw-500"
              data-cy="name-mode-button"
            />
          </v-tab>
        </v-tabs>

        <v-card
          flat
          tile
        >
          <v-row no-gutters>
            <v-col class="d-flex flex-grow-1 flex-shrink-0">
              <v-text-field
                v-model="searchQuery"
                @keydown.enter="searchProviders"
                @update:model-value="searchQueryChanged = true"
                id="search_input"
                ref="search"
                :aria-label="searchTab == 0 ? $t('Search by address') : $t('Search by name')"
                class="b-radius-0 elevation-0"
                data-cy="query-input"
                prepend-inner-icon="search"
                variant="solo"
                autofocus
                hide-details
                tile
              />
            </v-col>
            <v-col class="d-flex justify-end flex-grow-0 flex-shrink-0">
              <v-btn
                @click="searchProviders"
                :disabled="!searchQueryChanged"
                :height="58"
                :loading="searching"
                :ripple="false"
                class="focus-very-visible"
                color="primary"
                data-cy="search-button"
                size="x-large"
                tile
              >
                <span v-t="'Search'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <template v-if="subsidyProgram.selection_limit > 0">
          <v-row class="mb-3 mt-0">
            <v-col>
              <span class="fs-16 fw-800 c-primary">{{
                $t(`You can select ${subsidyProgram.selection_limit} total options`)
              }}</span>
            </v-col>
          </v-row>
        </template>

        <template v-if="!providerIds.length > 0">
          <template v-if="previousSubsidyProviderIds">
            <v-btn
              @click="copyPrevious"
              color="primary"
            >
              <v-icon class="me-2"> redo </v-icon>
              <span>{{ $t('Copy selections from:') + ' ' + previousChildName }}</span>
            </v-btn>
          </template>
        </template>

        <template v-if="matches.length == 0">
          <p
            class="fs-16 mt-2"
            data-cy="results-hint"
          >
            <span
              v-t="'Search by your address to see results near you.'"
              class="me-1"
            />
          </p>
        </template>

        <template v-if="searched && searchTab == 0">
          <v-row class="d-flex align-center mb-1 mt-2">
            <v-col
              cols="12"
              sm="8"
            >
              <template
                v-if="
                  $store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.enabled &&
                  $store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.public
                "
              >
                <FilterMenu
                  @clear="filters.vacancy = null"
                  @close="save()"
                  :actions="true"
                  :active="filters.vacancy"
                  :active-classes="['bg-white', 'bc-primary', 'b-1']"
                  :inactive-classes="['bg-white']"
                  :outlined="false"
                  data-cy="vacancy-filter"
                  elevation="2"
                  tid="search_availability_filter"
                  title="Availability"
                  hide-clear-filter
                >
                  <template #card>
                    <v-checkbox
                      v-model="filters.vacancy"
                      class="mt-0 mb-0"
                      color="primary"
                      false-icon="check_box_outline_blank"
                      true-icon="check_box"
                      hide-details
                    >
                      <template #label>
                        <span
                          class="c-black"
                          data-cy="availability-checkmark"
                          >{{ $t('Only show providers that have reported availability') }}</span
                        >
                      </template>
                    </v-checkbox>
                  </template>
                </FilterMenu>
              </template>

              <template v-if="!searchFlags.dobSearchEnabled && !searchFlags.defaultAgeGroupEnabled">
                <FilterMenu
                  @close="save()"
                  :actions="true"
                  :active="true"
                  :active-classes="['bg-white', 'bc-primary', 'b-1']"
                  :inactive-classes="['bg-white']"
                  :outlined="false"
                  data-cy="age-filter"
                  elevation="2"
                  tid="search_ages_filter"
                  title="Age"
                  hide-clear-filter
                >
                  <template #card>
                    <LabeledVolume
                      v-for="(item, index) in ageGroups"
                      @decrease="modifyCount(index, -1)"
                      @increase="modifyCount(index, 1)"
                      :key="index"
                      ref="childVolume"
                      :count="item.count"
                      :dense="true"
                      :subtitle="item.subtitle || item.subtext"
                      :title="item.title || item.text"
                    />
                  </template>
                </FilterMenu>
              </template>

              <v-btn
                v-if="searchFlags.moreFiltersEnabled"
                @click="moreFiltersDialogIsVisible = true"
                :class="moreFiltersButtonClass"
                class="focus-visible"
                data-cy="more-filters-button"
                data-tid="search_more_filter"
                elevation="2"
                rounded
              >
                <span
                  v-t="'More filters'"
                  class="bc-body c-black d-none d-md-flex fw-500 fs-14"
                  data-cy="more-filters"
                />
                <v-icon class="d-flex d-md-none fw-500"> more_horiz </v-icon>
              </v-btn>

              <v-dialog
                v-model="moreFiltersDialogIsVisible"
                width="600px"
                scrollable
              >
                <v-card>
                  <v-card-title class="mb-n-3 pb-0">
                    {{ $t('More filters') }}
                  </v-card-title>
                  <v-card-text class="pt-0 pb-6">
                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.program_types.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_types.alias || 'Program types',
                            )
                          }}
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <v-checkbox
                            v-for="type in $store.state.schemas[subsidyProgram.provider_schema_id]
                              .definition.properties.program_types.items.enum"
                            v-model="filters.program_types"
                            :key="type"
                            :label="capitalize($t(type))"
                            :value="type"
                            class="c-black mt-0"
                            color="primary"
                            hide-details
                            multiple
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.categories.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.alias || 'Categories',
                            )
                          }}
                        </v-col>
                      </v-row>

                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <div
                            v-if="
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.items.enum.length < 20
                            "
                          >
                            <v-checkbox
                              v-for="type in $store.state.schemas[subsidyProgram.provider_schema_id]
                                .definition.properties.categories.items.enum"
                              v-model="filters.categories"
                              :key="type"
                              :label="capitalize($t(type))"
                              :value="type"
                              class="c-black mt-0"
                              color="primary"
                              hide-details
                              multiple
                            />
                          </div>
                          <div v-else>
                            <v-autocomplete
                              v-model="filters.categories"
                              :items="
                                $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                  .properties.categories.items.enum
                              "
                              :label="$t('Select all that apply')"
                              variant="filled"
                              chips
                              deleteable-chips
                              hide-details
                              multiple
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.program_season.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              $store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_season.alias || 'Season',
                            )
                          }}
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <v-radio-group
                            v-model="filters.program_season"
                            class="mt-0"
                            hide-details
                          >
                            <v-radio
                              v-for="type in $store.state.schemas[subsidyProgram.provider_schema_id]
                                .definition.properties.program_season.enum"
                              :key="type"
                              :label="capitalize($t(type))"
                              :value="type"
                              class="c-black mt-0"
                              color="primary"
                              hide-details
                            />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.quality_rating_score.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{ $t('Quality rating') }}
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <div v-if="$store.state.site.ratings.Description">
                            <div class="c-light-black fs-15 pt-2 pb-4">
                              <span
                                v-text="$store.state.site.ratings.Description"
                                class="c-light-black fs-15"
                              />
                              <a
                                v-if="$store.state.site.ratings.Link"
                                v-t="'Learn more'"
                                :href="$store.state.site.ratings.Link"
                                class="c-primary fs-15 ms-1 underlined"
                                target="_blank"
                              />
                            </div>
                          </div>
                          <v-radio-group
                            v-model="filters.minimum_quality_rating"
                            class="mt-0 mb-2"
                            hide-details
                          >
                            <div
                              v-for="(level, index) in $store.state.site.ratings.text
                                .slice()
                                .reverse()"
                              :key="index"
                            >
                              <RadioWithContext
                                :index="index"
                                :item="level"
                                :suffix="
                                  $store.state.site.ratings.text.length - index <
                                  $store.state.site.ratings.text.length
                                    ? 'or higher'
                                    : null
                                "
                                :value="$store.state.site.ratings.max - index"
                              />
                              <v-divider
                                v-if="level.subtitle"
                                v-show="index + 1 < $store.state.site.ratings.text.length"
                                class="my-3"
                              />
                            </div>
                          </v-radio-group>
                          <v-btn
                            v-show="filters.minimum_quality_rating"
                            @click="filters.minimum_quality_rating = null"
                            color="primary"
                            size="small"
                          >
                            {{ $t('Clear filter') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-if="
                        $store.state.schemas[subsidyProgram.provider_schema_id].definition
                          .properties.hours.filterable
                      "
                    >
                      <v-divider class="my-6" />
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{ $t('Hours') }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <LabeledOpenClose
                          v-model="hoursRange"
                          close-title="Pickup time"
                          open-title="Drop-off time"
                          title-class="fs-14"
                        />
                      </v-row>
                      <div
                        v-t="
                          'We’ll show you results that offer drop-off at the time requested or earlier, and pick-up at the time requested or later'
                        "
                        class="c-gray fs-15 mt-2 mb-3 mxw-400"
                      />
                      <v-row dense>
                        <v-col
                          class="fw-600 fs-14"
                          cols="12"
                        >
                          {{ $t('Days of the week') }}
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Monday')"
                            class="mt-0"
                            density="compact"
                            value="0"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Tuesday')"
                            class="mt-2"
                            density="compact"
                            value="1"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Wednesday')"
                            class="mt-2"
                            density="compact"
                            value="2"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Thursday')"
                            class="mt-2"
                            density="compact"
                            value="3"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Friday')"
                            class="mt-2"
                            density="compact"
                            value="4"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Saturday')"
                            class="mt-2"
                            density="compact"
                            value="5"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Sunday')"
                            class="mt-2"
                            density="compact"
                            value="6"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                    </div>

                    <SearchMoreFilters v-model="filters" />
                  </v-card-text>
                  <v-divider />
                  <v-card-actions class="pt-2">
                    <v-btn
                      @click="clearMoreFilters()"
                      color="primary"
                      data-cy="clear-filters"
                      variant="text"
                    >
                      {{ $t('Clear filters') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      @click="save()"
                      color="primary"
                      data-cy="apply-filter"
                    >
                      {{ $t('Apply') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col
              class="ta-right"
              cols="12"
              sm="4"
            >
              <v-btn
                @click="listView = !listView"
                class="bg-white focus-very-visible"
                color="primary"
                data-cy="results-mode-button"
                variant="outlined"
              >
                <v-icon
                  v-show="!listView"
                  start
                >
                  west
                </v-icon>
                <span v-t="!listView ? 'List view' : 'Map view'" />
                <v-icon
                  v-show="listView"
                  end
                >
                  east
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-if="searchFlags.dobSearchEnabled"
            class="mb-3 mt-0"
          >
            <v-col>
              <span class="fs-16 mr-1">
                {{ $t(`Search results show options beginning on`) }}
              </span>
              <FilterMenu
                @open="openCareNeededDateDialog = !openCareNeededDateDialog"
                :actions="false"
                :active="true"
                :active-classes="['bg-white', 'bc-primary', 'b-1']"
                :inactive-classes="['bg-white', 'bc-very-light-grey']"
                :title="formattedCareNeededDate"
                classes="bc-outlined-gray"
              >
                <template #icon-suffix>
                  <v-icon
                    class="fs-24 me-2 c-primary hidden"
                    aria-hidden
                  />
                </template>
              </FilterMenu>
            </v-col>

            <v-dialog
              v-model="openCareNeededDateDialog"
              max-width="300"
            >
              <v-date-picker
                @update:model-value="changeCareNeededDate"
                :allowed-dates="allowedDates"
                :model-value="$vuetify.date.parseISO(careNeededDate)"
                scrollable
              >
                <template #default>
                  <v-row>
                    <v-col
                      class="d-flex justify-start"
                      cols="6"
                    >
                      <v-btn
                        @click="resetCareNeededDate"
                        :loading="localProcessing"
                        :ripple="false"
                        class="focus-very-visible"
                        color="primary"
                        size="large"
                        variant="outlined"
                      >
                        {{ $t('Reset') }}
                      </v-btn>
                    </v-col>
                    <v-col
                      class="d-flex justify-end"
                      cols="6"
                    >
                      <v-btn
                        @click="applyCareNeededDate()"
                        :loading="localProcessing"
                        :ripple="false"
                        class="focus-very-visible"
                        color="primary"
                        size="large"
                      >
                        {{ $t('Apply') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-date-picker>
            </v-dialog>
          </v-row>
        </template>

        <template v-if="searched && searchTab == 1">
          <v-row class="d-flex align-center mb-1 mt-2">
            <v-col
              class="ta-right"
              cols="12"
              sm="12"
            >
              <v-btn
                @click="listView = !listView"
                class="bg-white focus-very-visible"
                color="primary"
                data-cy="results-mode-button"
                variant="outlined"
              >
                <v-icon
                  v-show="!listView"
                  start
                >
                  west
                </v-icon>
                <span v-t="!listView ? 'List view' : 'Map view'" />
                <v-icon
                  v-show="listView"
                  end
                >
                  east
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <div v-show="!listView">
          <ProviderPreferenceMapResults
            @select="selectedMatch = $event"
            ref="map"
            :matches="matches"
            :search="search"
            :search-counter="searchCounter"
            :visible="!listView"
          />

          <template v-if="selectedMatch">
            <SimpleProviderCard
              @open="$emit('open', selectedMatch.id)"
              @toggle="$emit('toggle', [selectedMatch.id, $event])"
              :key="selectedMatch.id"
              :active="providerIds.includes(selectedMatch.id)"
              :provider="selectedMatch"
              :selectable="belowSelectionLimit"
            />
          </template>
        </div>

        <div v-show="listView">
          <SimpleProviderCard
            v-for="provider in matches"
            @open="$emit('open', provider.id)"
            @toggle="$emit('toggle', [provider.id, $event])"
            :key="provider.id"
            :active="providerIds.includes(provider.id)"
            :provider="provider"
            :selectable="belowSelectionLimit"
            class="my-4"
          />
        </div>

        <template v-if="matches.length > 0">
          <v-card
            class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
            tile
          >
            <v-pagination
              @update:model-value="changePage"
              :disabled="localProcessing"
              :length="pages"
              :model-value="page"
              :total-visible="8"
              rounded
            />
          </v-card>
        </template>
      </div>

      <vertical-spacer :min-height="50" />
    </div>

    <v-divider />

    <v-row class="my-4 px-4">
      <v-col class="d-flex justify-end">
        <v-btn
          @click="$emit('next')"
          :disabled="providerIds == 0"
          class="focus-very-visible"
          color="primary"
          size="x-large"
          tile
        >
          <span v-t="'Continue with selections'" />
          <v-icon class="ms-2"> east </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import childrenSearchParam from '@/parent/services/children-search-param';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import LabeledVolume from '@/shared/components/form/LabeledVolume.vue';
import ProviderPreferenceMapResults from '@/parent/components/subsidy/ProviderPreferenceMapResults.vue';
import RadioWithContext from '@/shared/components/form/RadioWithContext.vue';
import SearchFlags from '@/parent/services/search-flags';
import SearchMoreFilters from '@/shared/components/search/more_filters.vue';
import SimpleProviderCard from '@/shared/components/SimpleProviderCard.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { capitalize } from '@/plugins/filters';
import { scrollTo } from 'vuetify/lib/composables/goto';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledOpenClose,
    LabeledVolume,
    FilterMenu,
    ProviderPreferenceMapResults,
    RadioWithContext,
    SearchMoreFilters,
    SimpleProviderCard,
    VerticalSpacer,
  },

  mixins: [API],

  props: {
    child: {
      type: Object,
      default: null,
    },
    defaultQuery: {
      type: String,
      default: null,
    },
    previousChildName: {
      type: String,
      default: null,
    },
    previousSubsidyProviderIds: {
      type: Array,
      default: null,
    },
    processing: Boolean,
    providerIds: {
      type: Array,
      default: null,
    },
    selectedProviders: {
      type: Array,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
  },

  emits: ['copy', 'next', 'open', 'toggle'],

  setup() {
    return { capitalize };
  },

  data() {
    const searchFlags = new SearchFlags(this.$store);

    return {
      ageGroups: searchFlags.ageGroups,
      careNeededDate:
        this.subsidyProgram.search_for_care_as_of_date || new Date().toLocaleDateString('en-CA'),
      filters: this.createDefaultSearch(),
      hoursRange: [900, 1600],
      listView: true,
      localProcessing: this.processing,
      matches: [],
      moreFiltersDialogIsVisible: null,
      openCareNeededDateDialog: false,
      page: 1,
      pages: 1,
      search: null,
      searchCounter: 0,
      searched: false,
      searchFlags,
      searching: false,
      searchQuery: this.defaultQuery,
      searchQueryChanged: !!this.defaultQuery,
      searchTab: 0,
      selectedMatch: null,
      viewSelectedResults: false,
    };
  },

  computed: {
    belowSelectionLimit() {
      if (this.subsidyProgram.selection_limit === 0) {
        return true;
      }
      return this.subsidyProgram.selection_limit > this.providerIds.length;
    },

    formattedCareNeededDate() {
      return new Date(this.careNeededDate).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'utc',
      });
    },

    moreFiltersActive() {
      return (
        this.filters.categories.length > 0 ||
        Object.values(this.filters.highlights).some((item) => item.length > 0) ||
        Object.values(this.filters.custom).some((item) => item && item.length > 0) ||
        this.filters.minimum_quality_rating ||
        this.filters.payment_subsidies_accepted.length > 0 ||
        this.filters.program_season ||
        this.filters.program_types.length > 0 ||
        !!this.filters.subsidy
      );
    },

    moreFiltersButtonClass() {
      let c = ['me-2'];
      if (this.moreFiltersActive) {
        c = c.concat(['bg-white', 'bc-primary', 'b-1']);
      } else {
        c = c.concat(['bg-white', 'bc-very-light-gray']);
      }
      return c.join(' ');
    },
  },
  watch: {
    searchTab: {
      handler() {
        this.listView = true;
      },
    },
  },

  methods: {
    allowedDates(date) {
      const [year, month, day] = date.split('-').map(Number);
      const inputDate = new Date(year, month - 1, day);

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      return inputDate.getTime() >= currentDate.getTime();
    },

    applyCareNeededDate() {
      if (this.careNeededDateChanged) {
        this.save();
        this.openCareNeededDateDialog = false;
      }
    },

    changeCareNeededDate(value) {
      this.careNeededDate = this.$vuetify.date.toISO(value);
      this.searchQueryChanged = true;
      this.careNeededDateChanged = true;
    },

    createDefaultSearch() {
      return {
        categories: [],
        custom: {},
        dropoff: null,
        highlights: {},
        minimum_quality_rating: null,
        payment_subsidies_accepted: [],
        pickup: null,
        program_season: null,
        program_types: [],
        schema_id: this.subsidyProgram.provider_schema_id,
        vacancy: null,
        week_days: [],
      };
    },

    async changePage(newPage) {
      this.page = newPage;
      if (this.listView) {
        await scrollTo(0);
      }
      this.loadMatches();
    },

    clearHours() {
      this.hoursRange = [900, 1600];
      this.filters.dropoff = null;
      this.filters.pickup = null;
      this.filters.week_days = [];
    },

    clearMoreFilters() {
      this.clearHours();
      this.filters.categories = [];
      this.filters.custom = {};
      this.filters.highlights = {};
      this.filters.minimum_quality_rating = null;
      this.filters.payment_subsidies_accepted = [];
      this.filters.program_season = null;
      this.filters.program_types = [];
      this.moreFiltersDialogIsVisible = false;
      this.save();
    },

    copyPrevious() {
      this.viewSelectedResults = true;
      this.$emit('copy');
    },

    loadMatches() {
      this.api.match.index(
        this.search.id,
        {
          page: this.page,
          subsidy_program_id: this.subsidyProgram.id,
        },
        (response) => {
          this.searching = false;
          this.matches = response.data;
          this.pages = parseInt(response.headers['x-page-count'] || '0', 10);
          this.searchCounter += 1;
        },
      );
    },

    modifyCount(index, addition) {
      this.ageGroups[index].count += addition;
    },

    monthsAgo(dateFrom) {
      const dateTo = new Date();
      return (
        dateTo.getMonth() -
        dateFrom.getMonth() +
        12 * (dateTo.getFullYear() - dateFrom.getFullYear())
      );
    },

    resetCareNeededDate() {
      if (this.careNeededDate === this.subsidyProgram.search_for_care_as_of_date) return;

      this.careNeededDate = this.subsidyProgram.search_for_care_as_of_date;
      this.searchQueryChanged = true;
    },

    resetResults() {
      this.matches = [];
      this.localProcessing = false;
      this.searched = false;
      this.searchQuery = null;
    },

    save() {
      this.searchProviders();
    },

    searchByAddress() {
      if (this.filters.week_days.length > 0) {
        [this.filters.dropoff, this.filters.pickup] = this.hoursRange;
      } else {
        this.filters.dropoff = null;
        this.filters.pickup = null;
      }

      const children = childrenSearchParam({
        searchFlags: this.searchFlags,
        child: this.child,
        careNeededDate: this.careNeededDate,
        toArray: true,
      });

      this.searched = true;
      this.api.search.create(
        {
          ...this.filters,
          children,
          categories: this.filters.categories,
          unformatted_origin: this.searchQuery,
          location_type: 'home',
          subsidy_program_id: this.subsidyProgram.id,
        },
        (resp) => {
          this.search = resp.data;
          this.page = 1;
          this.searchQueryChanged = false;
          this.loadMatches();
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.searching = false;
        },
      );
    },

    searchByQuery() {
      this.searchCounter += 1;
      const counter = this.searchCounter;
      this.searched = true;
      this.api.public_api.provider.index(
        {
          include: 'details',
          query: this.searchQuery,
          schema_id: this.subsidyProgram.provider_schema_id,
          subsidy_program_id: this.subsidyProgram.id,
        },
        (response) => {
          if (counter === this.searchCounter) {
            this.matches = response.data;
            this.searching = false;
            this.searchQueryChanged = false;
            this.pages = 0;
          }
        },
      );
    },

    searchProviders() {
      this.searching = true;
      this.moreFiltersDialogIsVisible = false;

      if (!this.searchQuery) {
        this.matches = [];
        this.searching = false;
        return false;
      }

      if (this.searchTab === 0) {
        return this.searchByAddress();
      }
      return this.searchByQuery();
    },
  },
};
</script>
