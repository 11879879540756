<template>
  <div>
    <v-divider />

    <v-row
      dense
      no-gutters
    >
      <v-col
        @click="viewSelectedResults = false"
        @keydown.enter="viewSelectedResults = false"
        :class="!viewSelectedResults ? 'elevation-2' : 'elevation-0'"
        class="pointer"
      >
        <div
          v-t="'Search'"
          :class="!viewSelectedResults ? 'bg-primary c-white' : 'bg-white c-black'"
          class="fw-500 py-4 d-flex align-center justify-center"
          data-cy="search-mode-button"
          tabindex="0"
        />
      </v-col>
      <v-col
        @click="viewSelectedResults = true"
        @keydown.enter="viewSelectedResults = true"
        :class="viewSelectedResults ? 'elevation-2' : 'elevation-0'"
        class="pointer"
        cols="6"
        data-testid="viewSelectedResultsBtn"
      >
        <div
          v-text="$t('Selected') + ' (' + programIds.length + ')'"
          :class="viewSelectedResults ? 'bg-primary c-white' : 'bg-white c-black'"
          class="fw-500 py-4 d-flex align-center justify-center"
          data-cy="selected-mode-button"
          tabindex="0"
        />
      </v-col>
    </v-row>

    <v-divider />

    <div class="bg-super-light-blue px-4">
      <div
        v-show="viewSelectedResults"
        class="py-3"
      >
        <div
          v-for="(programId, index) in programIds"
          :key="programId"
          class="mb-6"
        >
          <SelectedProgramCard
            @move="$emit('move', [index, $event])"
            @open="$emit('open', $event)"
            @remove="$emit('remove', programId)"
            :api="api"
            :data-testid="'selectedResult' + index"
            :index="index"
            :length="programIds.length"
            :program-id="programId"
            class="my-4"
            active
            ordered
          />
        </div>
      </div>

      <div
        v-show="!viewSelectedResults"
        class="py-2"
      >
        <v-tabs
          v-model="searchTab"
          @update:model-value="resetResults"
          bg-color="transparent"
          class="mb-2"
        >
          <v-tab
            class="ls-normal tt-none fs-16 fw-500"
            data-cy="address-mode-button"
          >
            {{ $t('Search by address') }}
          </v-tab>
          <v-tab
            class="ls-normal tt-none fs-16 fw-500"
            data-cy="name-mode-button"
            data-testid="name-mode-button"
          >
            {{ $t('Search by name') }}
          </v-tab>
        </v-tabs>

        <v-card
          flat
          tile
        >
          <v-row no-gutters>
            <v-col class="d-flex flex-grow-1 flex-shrink-0">
              <v-text-field
                v-model="searchQuery"
                @keydown.enter="searchProviders"
                @update:model-value="searchQueryChanged = true"
                id="search_input"
                ref="search"
                :aria-label="searchTab == 0 ? $t('Search by address') : $t('Search by name')"
                class="b-radius-0 elevation-0"
                data-cy="query-input"
                data-testid="query-input"
                prepend-inner-icon="search"
                variant="solo"
                flat
                hide-details
                tile
              />
            </v-col>
            <v-col class="d-flex justify-end flex-grow-0 flex-shrink-0">
              <v-btn
                @click="searchProviders"
                :disabled="!searchQueryChanged"
                :height="58"
                :loading="searching"
                :ripple="false"
                class="focus-very-visible"
                color="primary"
                data-cy="search-button"
                data-testid="search-button"
                size="x-large"
                tabindex="0"
                tile
              >
                <span v-t="'Search'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <template v-if="subsidyProgram.selection_minimum || subsidyProgram.selection_limit">
          <v-row class="mt-0">
            <v-col>
              <span
                class="fs-16 fw-800 c-primary"
                data-testid="selection-text"
              >
                <template
                  v-if="subsidyProgram.selection_limit && !subsidyProgram.selection_minimum"
                >
                  {{ $t(`You can select ${subsidyProgram.selection_limit} total options`) }}
                </template>
                <template
                  v-else-if="!subsidyProgram.selection_limit && subsidyProgram.selection_minimum"
                >
                  {{ $t(`You must select ${subsidyProgram.selection_minimum} options`) }}
                </template>
                <template v-else>
                  {{
                    $t(
                      `You must select between ${subsidyProgram.selection_minimum} and ${subsidyProgram.selection_limit} options`,
                    )
                  }}
                </template>
              </span>
            </v-col>
          </v-row>
        </template>

        <template v-if="!programIds.length > 0">
          <template v-if="previousSubsidyProgramIds">
            <v-btn
              @click="copyPrevious"
              class="mt-2"
              color="primary"
            >
              <v-icon class="me-2"> redo </v-icon>
              <span>{{ $t('Copy selections from:') + ' ' + previousChildName }}</span>
            </v-btn>
          </template>
        </template>

        <template v-if="matches.length == 0 && searchCounter == 0">
          <p
            class="fs-16 mt-2"
            data-cy="results-hint"
          >
            <span
              v-t="'Search by your address to see results near you.'"
              class="me-1"
            />
          </p>
        </template>

        <template v-if="searched && searchTab == 0 && searchCounter > 0">
          <v-row
            class="d-flex align-center mt-2"
            data-testid="search-filters"
          >
            <v-col
              cols="12"
              sm="8"
            >
              <v-btn
                v-if="subsidyProgram.enable_search_dob_eligibility_filter"
                :class="moreFiltersButtonClass"
                class="focus-visible"
                elevation="0"
                variant="outlined"
                rounded
              >
                <v-checkbox
                  v-model="excludeIneligibleByDobChecked"
                  @update:model-value="toggleSearchDobEligibilityFilter"
                  :label="$t('Show eligible options only')"
                  density="compact"
                  outlined
                  rounded
                />
              </v-btn>
              <v-btn
                v-if="subsidyProgram.enable_show_seat_availability"
                :class="moreFiltersButtonClass"
                class="focus-visible"
                elevation="0"
                variant="outlined"
                rounded
              >
                <v-checkbox
                  v-model="filters.require_seat_availability"
                  @update:model-value="searchProviders"
                  :label="$t('Show programs with available seats only')"
                  density="compact"
                  outlined
                  rounded
                />
              </v-btn>

              <template
                v-if="
                  store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.enabled &&
                  store.state.schemas[subsidyProgram.provider_schema_id].meta.openings.public
                "
              >
                <FilterMenu
                  @clear="filters.vacancy = null"
                  @save="save"
                  :actions="true"
                  :active="filters.vacancy"
                  :active-classes="['bg-white', 'bc-primary', 'b-1']"
                  :inactive-classes="['bg-white', 'bc-very-light-grey']"
                  classes="bc-outlined-gray"
                  data-cy="vacancy-filter"
                  tid="search_availability_filter"
                  title="Availability"
                  hide-clear-filter
                >
                  <template #card>
                    <v-checkbox
                      v-model="filters.vacancy"
                      class="mt-0 mb-0"
                      color="primary"
                      false-icon="check_box_outline_blank"
                      true-icon="check_box"
                      hide-details
                    >
                      <template #label>
                        <span
                          class="c-black"
                          data-cy="availability-checkmark"
                        >
                          {{ $t('Only show providers that have reported availability') }}
                        </span>
                      </template>
                    </v-checkbox>
                  </template>
                </FilterMenu>
              </template>

              <v-btn
                v-if="searchFlags.moreFiltersEnabled"
                @click="moreFiltersDialogIsVisible = true"
                :class="moreFiltersButtonClass"
                class="focus-visible"
                data-cy="more-filters-button"
                data-tid="search_more_filter"
                elevation="0"
                variant="outlined"
                rounded
              >
                <span
                  v-t="'More filters'"
                  class="bc-body c-black d-none d-md-flex fw-500-important"
                  data-cy="more-filters"
                />
                <v-icon class="d-flex d-md-none fw-500"> more_horiz </v-icon>
              </v-btn>

              <v-dialog
                v-model="moreFiltersDialogIsVisible"
                tabindex="0"
                width="600px"
                scrollable
              >
                <v-card
                  border
                  flat
                  tile
                >
                  <v-card-title>
                    <v-row
                      class="d-flex align-center"
                      dense
                    >
                      <v-col class="d-flex justify-start">
                        <span v-t="'More filters'" />
                      </v-col>
                      <v-col class="d-flex justify-end">
                        <v-btn
                          @click="moreFiltersDialogIsVisible = false"
                          :aria-label="$t('Close')"
                          variant="text"
                          icon
                        >
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-divider class="mb-4" />
                  <v-card-text class="pt-0 pb-6">
                    <div
                      v-if="
                        store.state.schemas[subsidyProgram.provider_schema_id].definition.properties
                          .program_types.filterable
                      "
                    >
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_types.alias || 'Program types',
                            )
                          }}
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <v-checkbox
                            v-for="type in store.state.schemas[subsidyProgram.provider_schema_id]
                              .definition.properties.program_types.items.enum"
                            v-model="filters.program_types"
                            :key="type"
                            :label="capitalize($t(type))"
                            :value="type"
                            class="c-black mt-0"
                            color="primary"
                            hide-details
                            multiple
                          />
                        </v-col>
                      </v-row>
                      <v-divider class="my-6" />
                    </div>

                    <div
                      v-if="
                        store.state.schemas[subsidyProgram.provider_schema_id].definition.properties
                          .categories.filterable
                      "
                    >
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.alias || 'Categories',
                            )
                          }}
                        </v-col>
                      </v-row>

                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <div
                            v-if="
                              store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.categories.items?.enum?.length < 20
                            "
                          >
                            <v-checkbox
                              v-for="type in store.state.schemas[subsidyProgram.provider_schema_id]
                                .definition.properties.categories.items.enum"
                              v-model="filters.categories"
                              :key="type"
                              :label="capitalize($t(type))"
                              :value="type"
                              class="c-black mt-0"
                              color="primary"
                              hide-details
                              multiple
                            />
                          </div>
                          <div v-else>
                            <v-autocomplete
                              v-model="filters.categories"
                              :items="
                                store.state.schemas[subsidyProgram.provider_schema_id].definition
                                  .properties.categories.items.enum
                              "
                              :label="$t('Select all that apply')"
                              variant="filled"
                              chips
                              deleteable-chips
                              hide-details
                              multiple
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider class="my-6" />
                    </div>

                    <div
                      v-if="
                        store.state.schemas[subsidyProgram.provider_schema_id].definition.properties
                          .program_season.filterable
                      "
                    >
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{
                            $t(
                              store.state.schemas[subsidyProgram.provider_schema_id].definition
                                .properties.program_season.alias || 'Season',
                            )
                          }}
                        </v-col>
                      </v-row>
                      <v-row
                        class="mb-4"
                        dense
                      >
                        <v-col>
                          <v-radio-group
                            v-model="filters.program_season"
                            class="mt-0"
                            hide-details
                          >
                            <v-radio
                              v-for="type in store.state.schemas[subsidyProgram.provider_schema_id]
                                .definition.properties.program_season.enum"
                              :key="type"
                              :label="capitalize($t(type))"
                              :value="type"
                              class="c-black mt-0"
                              color="primary"
                              hide-details
                            />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-divider class="my-6" />
                    </div>

                    <div
                      v-if="
                        store.state.schemas[subsidyProgram.provider_schema_id].definition.properties
                          .quality_rating_score.filterable
                      "
                    >
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{ $t('Quality rating') }}
                        </v-col>
                      </v-row>

                      <v-row dense>
                        <v-col>
                          <div v-if="store.state.site.ratings.Description">
                            <div class="c-light-black fs-15 pt-2 pb-4">
                              <span
                                v-text="store.state.site.ratings.Description"
                                class="c-light-black fs-15"
                              />
                              <a
                                v-if="store.state.site.ratings.Link"
                                v-t="'Learn more'"
                                :href="store.state.site.ratings.Link"
                                class="c-primary fs-15 ms-1 underlined"
                                target="_blank"
                              />
                            </div>
                          </div>
                          <v-radio-group
                            v-model="filters.minimum_quality_rating"
                            class="mt-0 mb-2"
                            hide-details
                          >
                            <div
                              v-for="(level, index) in store.state.site.ratings.text
                                .slice()
                                .reverse()"
                              :key="index"
                            >
                              <RadioWithContext
                                :index="index"
                                :item="level"
                                :suffix="
                                  store.state.site.ratings.text.length - index <
                                  store.state.site.ratings.text.length
                                    ? 'or higher'
                                    : null
                                "
                                :value="store.state.site.ratings.max - index"
                              />
                              <v-divider
                                v-if="level.subtitle"
                                v-show="index + 1 < store.state.site.ratings.text.length"
                                class="my-3"
                              />
                            </div>
                          </v-radio-group>
                          <v-btn
                            v-show="filters.minimum_quality_rating"
                            @click="filters.minimum_quality_rating = null"
                            color="primary"
                            size="small"
                          >
                            {{ $t('Clear filter') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider class="my-6" />
                    </div>

                    <div
                      v-if="
                        store.state.schemas[subsidyProgram.provider_schema_id].definition.properties
                          .hours.filterable
                      "
                    >
                      <v-row dense>
                        <v-col class="c-black fs-16 fw-600">
                          {{ $t('Hours') }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <LabeledOpenClose
                          v-model="hoursRange"
                          close-title="Pickup time"
                          open-title="Drop-off time"
                          tabindex="0"
                          title-class="fs-14"
                        />
                      </v-row>

                      <div
                        v-t="
                          'We’ll show you results that offer drop-off at the time requested or earlier, and pick-up at the time requested or later'
                        "
                        class="c-gray fs-15 mt-2 mb-3 mxw-400"
                      />

                      <v-row dense>
                        <v-col
                          class="fw-600 fs-14"
                          cols="12"
                        >
                          {{ $t('Days of the week') }}
                        </v-col>
                        <v-col cols="12">
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Monday')"
                            class="mt-0"
                            density="compact"
                            value="0"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Tuesday')"
                            class="mt-2"
                            density="compact"
                            value="1"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Wednesday')"
                            class="mt-2"
                            density="compact"
                            value="2"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Thursday')"
                            class="mt-2"
                            density="compact"
                            value="3"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Friday')"
                            class="mt-2"
                            density="compact"
                            value="4"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Saturday')"
                            class="mt-2"
                            density="compact"
                            value="5"
                            hide-details
                          />
                          <v-checkbox
                            v-model="filters.week_days"
                            :label="$t('Sunday')"
                            class="mt-2"
                            density="compact"
                            value="6"
                            hide-details
                          />
                        </v-col>
                      </v-row>
                      <v-divider class="my-6" />
                    </div>

                    <SearchMoreFilters v-model="filters" />
                  </v-card-text>

                  <v-divider />

                  <v-card-actions class="pt-2">
                    <v-btn
                      @click="clearMoreFilters()"
                      color="primary"
                      data-cy="clear-filters"
                      variant="text"
                    >
                      {{ $t('Clear filters') }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      @click="searchProviders()"
                      color="primary"
                      data-cy="apply-filter"
                    >
                      {{ $t('Apply') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col
              class="ta-right"
              cols="12"
              sm="4"
            >
              <v-btn
                @click="listView = !listView"
                class="bg-white focus-very-visible"
                color="primary"
                data-cy="results-mode-button"
                variant="outlined"
              >
                <v-icon
                  v-show="!listView"
                  start
                >
                  west
                </v-icon>
                <span v-t="!listView ? 'List view' : 'Map view'" />
                <v-icon
                  v-show="listView"
                  end
                >
                  east
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            v-if="searchFlags.dobSearchEnabled"
            class="mb-3 mt-0"
          >
            <v-col>
              <span class="fs-16 mr-1">
                {{ $t(`Search results show options beginning on`) }}
              </span>
              <FilterMenu
                @open="openCareNeededDateDialog = !openCareNeededDateDialog"
                :actions="false"
                :active="true"
                :active-classes="['bg-white', 'bc-primary', 'b-1']"
                :inactive-classes="['bg-white', 'bc-very-light-grey']"
                :title="formattedCareNeededDate"
                classes="bc-outlined-gray"
              >
                <template #icon-suffix>
                  <v-icon
                    class="fs-24 me-2 c-primary hidden"
                    aria-hidden
                  />
                </template>
              </FilterMenu>
            </v-col>

            <v-dialog
              v-model="openCareNeededDateDialog"
              max-width="300"
            >
              <v-date-picker
                @update:model-value="changeCareNeededDate"
                :allowed-dates="allowedDates"
                :model-value="$vuetify.date.parseISO(careNeededDate)"
                scrollable
              >
                <template #default>
                  <v-row>
                    <v-col
                      class="d-flex justify-start"
                      cols="6"
                    >
                      <v-btn
                        @click="resetCareNeededDate"
                        :loading="processing"
                        :ripple="false"
                        class="focus-very-visible"
                        color="primary"
                        size="large"
                        variant="outlined"
                      >
                        {{ $t('Reset') }}
                      </v-btn>
                    </v-col>
                    <v-col
                      class="d-flex justify-end"
                      cols="6"
                    >
                      <v-btn
                        @click="applyCareNeededDate()"
                        :loading="processing"
                        :ripple="false"
                        class="focus-very-visible"
                        color="primary"
                        size="large"
                      >
                        {{ $t('Apply') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-date-picker>
            </v-dialog>
          </v-row>
        </template>

        <template v-if="searched && searchTab == 1">
          <v-row class="d-flex align-center mb-1 mt-2">
            <v-col
              class="ta-right"
              cols="12"
              sm="12"
            >
              <v-btn
                @click="listView = !listView"
                class="bg-white focus-very-visible"
                color="primary"
                data-cy="results-mode-button"
                variant="outlined"
              >
                <v-icon
                  v-show="!listView"
                  start
                >
                  west
                </v-icon>
                <span v-t="!listView ? 'List view' : 'Map view'" />
                <v-icon
                  v-show="listView"
                  end
                >
                  east
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <div v-show="!listView">
          <ProviderPreferenceMapResults
            @select="selectedMatch = $event"
            ref="map"
            :matches="matches"
            :search="search"
            :search-counter="searchCounter"
            :visible="!listView"
          />

          <template v-if="selectedMatch">
            <ProviderProgramsCard
              @check="$emit('check', $event)"
              @open="$emit('open', selectedMatch.id)"
              @toggle="$emit('toggle', $event)"
              :key="selectedMatch.id"
              :dob="child.dob"
              :program-ids="programIds"
              :provider="selectedMatch"
              :selectable="belowSelectionLimit"
              :subsidy-program="subsidyProgram"
            />
          </template>
        </div>

        <div v-show="listView">
          <ProviderProgramsCard
            v-for="provider in matches"
            @check="$emit('check', $event)"
            @open="$emit('open', provider.id)"
            @toggle="$emit('toggle', $event)"
            :key="provider.id"
            :dob="child.dob"
            :program-ids="programIds"
            :provider="provider"
            :selectable="belowSelectionLimit"
            :subsidy-program="subsidyProgram"
            class="my-4"
          />
        </div>

        <template v-if="matches.length > 0 && searchTab == 0">
          <v-card
            class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
            tile
          >
            <v-pagination
              @update:model-value="changePage($event)"
              :disabled="processing"
              :length="pages"
              :model-value="page"
              :total-visible="8"
              rounded
            />
          </v-card>
        </template>
        <v-card
          v-show="searched && !searching && matches.length === 0"
          class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
          tile
        >
          <v-alert
            border="start"
            class="w-100pc mt-6 fs-20"
            color="secondary"
            type="info"
          >
            <div>
              <MarkdownContent :content="noProviderResultsMessage()" />
            </div>
          </v-alert>
        </v-card>
      </div>

      <vertical-spacer :min-height="50" />
    </div>

    <v-divider />

    <v-row class="my-4 px-4">
      <v-col class="d-flex justify-end">
        <v-btn
          @click="$emit('next')"
          :disabled="programIds.length < (subsidyProgram.selection_minimum || 1)"
          class="focus-very-visible"
          color="primary"
          data-testid="selectionsContinueButton"
          size="x-large"
        >
          <span v-t="'Continue with selections'" />
          <v-icon class="ms-2"> east </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import api from '@/shared/services/all_bright_finder';
import { calculateMonthsOldOnDate } from '@/parent/services/calculate-months-old-on-date';
import childrenSearchParam from '@/parent/services/children-search-param';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import ProviderPreferenceMapResults from '@/parent/components/subsidy/ProviderPreferenceMapResults.vue';
import RadioWithContext from '@/shared/components/form/RadioWithContext.vue';
import SearchFlags from '@/parent/services/search-flags';
import SearchMoreFilters from '@/shared/components/search/more_filters.vue';
import SelectedProgramCard from '@/parent/components/subsidy/SelectedProgramCard.vue';
import ProviderProgramsCard from '@/parent/components/subsidy/ProviderProgramsCard.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { capitalize } from '@/plugins/filters';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import useEventBus from '@/shared/composables/useEventBus';

const emit = defineEmits(['check', 'copy', 'move', 'next', 'open', 'remove', 'toggle']);
const props = defineProps({
  child: {
    type: Object,
    default: null,
  },
  defaultQuery: {
    type: String,
    default: null,
  },
  previousChildName: {
    type: String,
    default: null,
  },
  previousSubsidyProgramIds: {
    type: Array,
    default: null,
  },
  processing: Boolean,
  programIds: {
    type: Array,
    default: null,
  },
  selectedProviders: {
    type: Array,
    default: null,
  },
  showSelected: Boolean,
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

const { locale } = useI18n();
const eventBus = useEventBus();
const store = useStore();

const searchFlags = new SearchFlags(store);

const careNeededDate = ref(
  props.subsidyProgram.search_for_care_as_of_date || new Date().toLocaleDateString('en-CA'),
);
const careNeededDateChanged = ref(false);
const excludeIneligibleByDobChecked = ref(false);
const filters = reactive({
  categories: [],
  custom: {},
  dropoff: null,
  eligibility_dob: null,
  highlights: {},
  minimum_quality_rating: null,
  payment_subsidies_accepted: [],
  pickup: null,
  program_season: null,
  program_types: [],
  schema_id: props.subsidyProgram.provider_schema_id,
  vacancy: null,
  week_days: [],
});
const hoursRange = ref([900, 1600]);
const listView = ref(true);
const matches = ref([]);
const moreFiltersDialogIsVisible = ref(null);
const openCareNeededDateDialog = ref(false);
const page = ref(1);
const pages = ref(1);
const search = ref(null);
const searchCounter = ref(0);
const searched = ref(false);
const searching = ref(false);
const searchQuery = ref(props.defaultQuery);
const searchQueryChanged = ref(!!props.defaultQuery);
const searchTab = ref(0);
const selectedMatch = ref(null);
const viewSelectedResults = ref(props.showSelected);

const belowSelectionLimit = computed(() => {
  if (props.subsidyProgram.selection_limit) {
    return props.programIds.length < props.subsidyProgram.selection_limit;
  }
  return true;
});

const formattedCareNeededDate = computed(() => {
  return new Date(careNeededDate.value).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  });
});

const moreFiltersActive = computed(() => {
  return (
    filters.categories.length > 0 ||
    Object.values(filters.highlights).some((highlight) => highlight.length > 0) ||
    Object.values(filters.custom).some((item) => item && item.length > 0) ||
    filters.minimum_quality_rating ||
    filters.payment_subsidies_accepted.length > 0 ||
    filters.program_season ||
    filters.program_types.length > 0 ||
    !!filters.subsidy
  );
});

const moreFiltersButtonClass = computed(() => {
  let classes = ['me-2', 'bc-outlined-gray'];

  if (moreFiltersActive.value) {
    classes = classes.concat(['bg-white', 'bc-primary', 'b-1']);
  } else {
    classes = classes.concat(['bg-white', 'bc-very-light-gray']);
  }

  return classes.join(' ');
});

function allowedDates(date) {
  const [year, month, day] = date.split('-').map(Number);
  const inputDate = new Date(year, month - 1, day);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return inputDate.getTime() >= currentDate.getTime();
}

function applyCareNeededDate() {
  if (careNeededDateChanged.value) {
    save();
    openCareNeededDateDialog.value = false;
  }
}

function changePage(newPage) {
  page.value = newPage;
  searchProviders();
}

function clearHours() {
  hoursRange.value = [900, 1600];
  filters.dropoff = null;
  filters.pickup = null;
  filters.week_days = [];
}

function clearMoreFilters() {
  clearHours();
  filters.categories = [];
  filters.custom = {};
  filters.highlights = {};
  filters.minimum_quality_rating = null;
  filters.payment_subsidies_accepted = [];
  filters.program_season = null;
  filters.program_types = [];
  moreFiltersDialogIsVisible.value = false;
  searchProviders();
}

function copyPrevious() {
  viewSelectedResults.value = true;
  emit('copy');
}

function filterMatches(providers) {
  const monthsOld = calculateMonthsOldOnDate(props.child.dob, careNeededDate.value);

  return providers
    .map((provider) => ({
      ...provider,
      programs: provider.programs.filter(
        (program) => program.age_max >= monthsOld && monthsOld >= program.age_min,
      ),
    }))
    .filter((provider) => provider.programs.length > 0);
}

function loadMatches() {
  matches.value = [];
  api.match.index(
    search.value.id,
    {
      page: page.value,
      page_size: 10,
      include: 'details,programs',
      subsidy_program_id: props.subsidyProgram.id,
    },
    (response) => {
      searching.value = false;
      matches.value = filterMatches(response.data);
      pages.value = parseInt(response.headers['x-page-count'] || '0', 10);
      searchCounter.value += 1;
    },
  );
}

function noProviderResultsMessage() {
  if (props.subsidyProgram.no_provider_results_found_message) {
    return props.subsidyProgram.no_provider_results_found_message;
  }
  return 'No providers were found that matched your search criteria. Please try adjusting your search to see additional results.';
}

function resetCareNeededDate() {
  if (careNeededDate.value === props.subsidyProgram.search_for_care_as_of_date) return;

  careNeededDate.value = props.subsidyProgram.search_for_care_as_of_date;
  searchQueryChanged.value = true;
}

function resetResults() {
  matches.value = [];
  searched.value = false;
  searchQuery.value = null;
}

function save() {
  searchProviders();
}

function searchByAddress() {
  if (filters.week_days.length > 0) {
    filters.dropoff = hoursRange.value[0];
    filters.pickup = hoursRange.value[1];
  } else {
    filters.dropoff = null;
    filters.pickup = null;
  }

  const children = childrenSearchParam({
    searchFlags,
    child: props.child,
    careNeededDate: careNeededDate.value,
    toArray: true,
  });

  searched.value = true;
  api.search.create(
    {
      ...filters,
      children,
      categories: filters.categories,
      unformatted_origin: searchQuery.value,
      location_type: 'home',
      subsidy_program_id: props.subsidyProgram.id,
    },
    (resp) => {
      search.value = resp.data;
      page.value = 1;
      searchQueryChanged.value = false;
      loadMatches();
    },
    (err) => {
      eventBus.error(err);
      searching.value = false;
    },
  );
}

function searchByQuery() {
  searchCounter.value += 1;
  const counter = searchCounter.value;
  searched.value = true;
  matches.value = [];
  api.public_api.provider.index(
    {
      include: 'details,programs',
      query: searchQuery.value,
      schema_id: props.subsidyProgram.provider_schema_id,
      subsidy_program_id: props.subsidyProgram.id,
    },
    (response) => {
      if (counter === searchCounter.value) {
        matches.value = filterMatches(response.data);
        searching.value = false;
        searchQueryChanged.value = false;
        pages.value = 0;
      }
    },
  );
}

function searchProviders() {
  searching.value = true;
  moreFiltersDialogIsVisible.value = false;

  if (!searchQuery.value) {
    matches.value = [];
    searching.value = false;
    return;
  }

  if (searchTab.value === 0) {
    searchByAddress();
  } else {
    searchByQuery();
  }
}

function toggleSearchDobEligibilityFilter() {
  filters.eligibility_dob = excludeIneligibleByDobChecked.value ? props.child.dob : null;
  save();
}

watch(searchTab, () => {
  listView.value = true;
});
</script>
