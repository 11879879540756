<template>
  <div>
    <LabeledVolume
      v-for="(item, index) in value"
      @decrease="modifyCount(index, -1)"
      @increase="modifyCount(index, 1)"
      :key="index"
      ref="volumeRefs"
      :count="item.count"
      :dense="value.length > 6"
      :subtitle="item.subtitle || item.subtext"
      :title="item.title || item.text"
    />
  </div>
</template>

<script setup>
import LabeledVolume from '@/shared/components/form/LabeledVolume.vue';

const props = defineProps({
  value: {
    type: Array,
    default: null,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change']);
const volumeRefs = ref([]);

nextTick(() => {
  if (volumeRefs.value[0]) {
    volumeRefs.value[0].focus();
  }
});

const modifyCount = (index, addition) => {
  const updatedValue = [...props.value];
  updatedValue[index].count += addition;
  emit('change', updatedValue);
};
</script>
