<template>
  <div v-if="offers.length > 0">
    <EnrollmentCard
      v-for="(enrollment, index) in offers"
      @change:status="updateEnrollmentStatus(enrollment, $event)"
      :key="'offer' + enrollment.id"
      :class="cardClass(index, offers.length)"
      :data-testid="`enrollment-card-${enrollment.id}`"
      :enrollment="enrollment"
      :index="index"
      :processing="processing"
      :removable="false"
      :reorderable="false"
      :show-action-menu="false"
      :subsidy-program="subsidyProgram"
      ordered
      outlined
    />
  </div>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import EnrollmentCard from '../EnrollmentCard.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { ENROLLMENT_STATUSES } from '@/shared/assets/constants';

const eventBus = useEventBus();
const emit = defineEmits(['changed']);

const processing = defineModel('processing', {
  type: Boolean,
  default: false,
});

const props = defineProps({
  childData: {
    type: Object,
    required: true,
  },
  subsidyProgram: {
    type: Object,
    default: null,
  },
});

defineExpose({ loadOffers });

const offers = ref([]);

function cardClass(index, length) {
  return {
    'mb-4': length > 1 && index < length - 1,
  };
}

async function loadOffers() {
  const resp = await Api.parent.enrollment.index({ child_id: props.childData.id });

  if (resp?.data) {
    const enrollments = resp.data;

    const filteredEnrollments = enrollments.filter((enrollment) => {
      return (
        (enrollment.public_status === ENROLLMENT_STATUSES.OFFERED ||
          enrollment.public_status === ENROLLMENT_STATUSES.ACCEPTED) &&
        enrollment.subsidy_program_id === props.subsidyProgram.id
      );
    });

    offers.value = filteredEnrollments.sort((a, b) => a.preference_order - b.preference_order);
  }
}

async function updateEnrollmentStatus(enrollment, newVal) {
  processing.value = true;
  await Api.parent.enrollment_status
    .update(enrollment.id, newVal)
    .catch((error) => eventBus.error(error));

  await loadOffers();
  emit('changed');
  processing.value = false;
}

onMounted(async () => {
  await loadOffers();
});
</script>
