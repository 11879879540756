<template>
  <v-card
    :aria-label="childData.name"
    tabindex="0"
    border
    flat
    tile
  >
    <v-card-text class="ta-center">
      <v-avatar
        class="fs-96 c-white mb-6"
        color="primary"
        data-cy="child-initials"
        size="200"
        aria-hidden
      >
        {{ [childData.first_name[0], childData.last_name[0]].join('').toUpperCase() }}
      </v-avatar>
      <div
        v-text="childData.name"
        class="fs-24 mb-4"
        data-cy="child-name"
      />
    </v-card-text>

    <v-row v-if="store.state.pages.Attendance.enabled">
      <v-col>
        <div class="pa-4">
          <v-btn
            @click="draftCheckInOut"
            :variant="checkedIn ? 'outlined' : undefined"
            color="primary"
            block
          >
            <template v-if="checkedIn">
              <v-icon class="me-2"> check_circle </v-icon>
              <span v-t="'Checked in'" />
            </template>
            <template v-else>
              <span v-t="'Check in'" />
            </template>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-divider />
    <v-row
      class="ta-left"
      dense
    >
      <v-col>
        <v-btn
          @click="router.push({ name: 'ChildShow', params: { childId: childData.id } })"
          class="focus-very-visible td-none justify-space-between"
          data-cy="view_child_info"
          size="x-large"
          variant="text"
          block
          tile
        >
          <span>{{ t('Submit and view applications') }}</span>
          <template #append>
            <v-icon color="primary"> east </v-icon>
          </template>
        </v-btn>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="checkInOut"
      ref="checkInOutDialog"
      :processing="processing"
      :save-button-text="checkInOutButtonText"
      :title="checkedIn ? 'Check-out' : 'Check-in'"
    >
      <template #form>
        <p
          v-t="checkInOutText"
          class="fs-16"
        />
        <EsignCanvas ref="esignCanvas" />
      </template>
    </ResourceDialog>
  </v-card>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Api from '@/shared/services/bright_finder';
import EsignCanvas from '@/shared/components/form/EsignCanvas.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const props = defineProps({
  childData: {
    type: Object,
    required: true,
  },
});

const eventBus = useEventBus();
const { locale, t } = useI18n();
const store = useStore();
const router = useRouter();

const checkedIn = ref(props.childData.checked_in);
const checkInOutDialog = ref(null);
const dateTime = ref('');
const esignCanvas = ref(null);
const position = ref(null);
const processing = ref(false);
const signature = ref(null);

const checkInOutText = computed(() => {
  return [
    'Add your signature to',
    checkedIn.value ? 'check-out' : 'check-in',
    `<strong>${props.childData.name}</strong>`,
    'at',
    `<strong>${dateTime.value}</strong>.`,
  ].join(' ');
});

const checkInOutButtonText = computed(() => {
  return checkedIn.value ? 'Check-out' : 'Check-in';
});

function checkInOut() {
  if (esignCanvas.value.filled()) {
    processing.value = true;
    const params = {
      event_type: checkedIn.value ? 'out' : 'in',
      signature: esignCanvas.value?.getDataUrl(),
      child_id: props.childData.id,
      latitude: position.value?.latitude,
      longitude: position.value?.longitude,
    };

    Api.parent.attendance_event.create(
      params,
      () => {
        checkedIn.value = !checkedIn.value;
        processing.value = false;
        checkInOutDialog.value?.close();
        signature.value = null;
        esignCanvas.value?.clear();
      },
      (err) => {
        eventBus.error(err);
      },
    );
  } else {
    eventBus.chime('Add your signature to submit');
  }
}

function draftCheckInOut() {
  dateTime.value = getDateTime();
  position.value = {
    latitude: 37.7749,
    longitude: 122.4194,
  };
  checkInOutDialog.value?.open();
}

function getDateTime() {
  const options = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Los_Angeles',
  };
  return new Date().toLocaleDateString(locale.value, options);
}
</script>
