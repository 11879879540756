<template>
  <FormQuestion
    @back="$emit('back')"
    @next="validateAndNext"
    :header="$t('Child') + ` - ${childData.name}`"
    :hide-actions="hideActions"
    :next-disabled="nextDisabled"
    :processing="processing"
    :subtitle="subsidyProgram.preference_subtitle"
    :title="subsidyProgram.preference_title"
  >
    <ProviderReferredProgramCard
      v-if="referredProvider && showReferredProviderCard && step === 1"
      @confirm="(programId) => handleReferredProgramConfirm(programId)"
      @open="openProvider(referredProvider?.id)"
      :provider="referredProvider"
      class="mb-5"
      data-testid="providerReferredProgramCard"
    />

    <v-stepper-vertical
      v-model="step"
      class="pb-0 full-width"
      flat
      hide-actions
      tile
    >
      <v-card
        class="mb-4 focus-very-visible w-100pc"
        data-testid="selectProgramsStep"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <div
            ref="stepHeader"
            class="focus-very-visible step-header"
            tabindex="0"
          >
            <v-stepper-vertical-item
              :complete="step > 1"
              value="1"
            >
              <template #title>
                <h3
                  v-t="subsidyProgram.select_providers_title"
                  tabindex="0"
                />
                <h4
                  v-t="subsidyProgram.select_providers_subtitle"
                  class="fs-16 c-light-black"
                  tabindex="0"
                />
              </template>

              <SelectProgramsStep
                @check="verifySelectionLimit"
                @copy="copyPreferences"
                @move="$emit('reorderPrograms', $event[0], $event[0] + $event[1])"
                @next="validateStepChange(2)"
                @open="openProvider($event)"
                @remove="remove"
                @toggle="toggleSelection($event[0], $event[1])"
                ref="stepComponent"
                :api="api"
                :child="childData"
                :default-query="defaultQuery"
                :previous-child-name="previousChildName"
                :previous-subsidy-program-ids="previousSubsidyProgramIds"
                :processing="processing"
                :program-ids="subsidy.program_ids"
                :show-selected="showSelected"
                :subsidy-program="subsidyProgram"
              />
            </v-stepper-vertical-item>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        class="mb-4 w-100pc"
        data-testid="rankProgramsStep"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <div
            ref="stepHeader"
            class="focus-very-visible step-header"
            tabindex="0"
          >
            <v-stepper-vertical-item
              :complete="step > 2"
              value="2"
            >
              <template #title>
                <h3
                  v-t="subsidyProgram.rank_providers_title"
                  tabindex="0"
                />
                <h4
                  v-t="subsidyProgram.rank_providers_subtitle"
                  class="fs-16 c-light-black"
                  tabindex="0"
                />
              </template>

              <RankProgramsStep
                @back="changeStep(step - 1)"
                @move="$emit('reorderPrograms', $event[0], $event[0] + $event[1])"
                @next="changeStep(step + 1)"
                @open="openProvider($event)"
                @remove="remove"
                @toggle="remove($event[0])"
                ref="stepComponent"
                :api="api"
                :hide-remove="true"
                :program-ids="subsidy.program_ids"
                :show-next="showNextOnStep(step)"
                :subsidy-program="subsidyProgram"
              />
            </v-stepper-vertical-item>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        v-if="conditionalSteps?.includes('current_provider_id')"
        class="mb-4 w-100pc"
        data-testid="currentEnrollmentStep"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <v-stepper-vertical-item
            :complete="step > conditionalSteps.indexOf('current_provider_id')"
            :value="conditionalSteps.indexOf('current_provider_id')"
          >
            <template #title>
              <h3
                v-t="subsidyProgram.current_provider_title"
                tabindex="0"
              />
            </template>
            <CurrentEnrollmentStep
              v-model="currentProviderId"
              @back="changeStep(step - 1)"
              @na="currentProviderNotApplicableClicked"
              @next="changeStep(step + 1)"
              @update:model-value="selectCurrentProvider"
              ref="stepComponent"
              :na="currentProviderNotApplicable"
              :providers="selectedProviders"
              :show-next="showNextOnStep(step)"
              :subsidy-program="subsidyProgram"
              :valid="currentProviderSelectionValid"
            />
          </v-stepper-vertical-item>
        </v-card-text>
      </v-card>

      <v-card
        v-if="conditionalSteps?.includes('sibling_provider_ids')"
        class="mb-4 w-100pc"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <v-stepper-vertical-item
            :complete="step > conditionalSteps.indexOf('sibling_provider_ids')"
            :value="conditionalSteps.indexOf('sibling_provider_ids')"
          >
            <template #title>
              <h3
                v-t="subsidyProgram.sibling_provider_title"
                tabindex="0"
              />
            </template>

            <SiblingSelectionStep
              v-model="siblingProviderIds"
              @back="changeStep(step - 1)"
              @na="siblingNotApplicableClicked"
              @next="changeStep(step + 1)"
              @update:model-value="selectSiblings"
              ref="stepComponent"
              :na="siblingNotApplicable"
              :providers="selectedProviders"
              :show-next="showNextOnStep(step)"
              :subsidy-program="subsidyProgram"
              :valid="siblingSelectionValid"
            />
          </v-stepper-vertical-item>
        </v-card-text>
      </v-card>
      <v-card
        v-if="conditionalSteps?.includes('staff_provider_ids')"
        class="mb-4 w-100pc"
        border
        flat
        tile
      >
        <v-card-text class="py-0 px-0">
          <v-stepper-vertical-item
            :complete="step > conditionalSteps.indexOf('staff_provider_ids')"
            :value="conditionalSteps.indexOf('staff_provider_ids')"
          >
            <template #title>
              <h3
                v-t="subsidyProgram.staff_provider_title"
                tabindex="0"
              />
            </template>

            <StaffSelectionStep
              v-model="staffProviderIds"
              @back="changeStep(step - 1)"
              @na="staffNotApplicableClicked"
              @update:model-value="selectStaff"
              ref="stepComponent"
              :na="staffNotApplicable"
              :providers="selectedProviders"
              :subsidy-program="subsidyProgram"
            />
          </v-stepper-vertical-item>
        </v-card-text>
      </v-card>
    </v-stepper-vertical>

    <ContentDialog
      ref="providerDialog"
      :max-width="800"
    >
      <template #content="{ data }">
        <ProviderMatch
          v-if="data"
          @change="$emit('change')"
          :match="data"
          :outlined="false"
          :schema="providerSchema"
        />
      </template>
    </ContentDialog>

    <ResourceDialog
      @save="confirmNoReferredProgram()"
      ref="noReferredProgramSelectedDialog"
      :cancellable="true"
      :closeable="true"
      :max-width="600"
      save-button-text="Proceed without the site selected"
      title="Are you sure you want to continue?"
    >
      <template #form>
        <v-row data-testid="noReferredProgramSelectedDialog">
          <v-col cols="12">
            <div class="fs-14">
              This individual has been recognized as either a present student, a sibling of a
              student, or a family member of someone employed by {{ referredProvider?.name }}. They
              were not listed among your choices. If you continue without selecting this location,
              your priority at this site will not be ensured.
            </div>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="confirmNoReferredProviderInProviderSelections()"
      ref="noReferredProviderInSelectedProvidersDialog"
      :cancellable="true"
      :closeable="true"
      :max-width="600"
      save-button-text="Proceed without the provider selected"
      title="Are you sure you want to continue?"
    >
      <template #form>
        <v-row data-testid="noReferredProviderInSelectedProvidersDialog">
          <v-col cols="12">
            <div class="fs-14">
              This individual has been recognized as either a present student, a sibling of a
              student, or a family member of someone employed by {{ referredProvider?.name }}. You
              did not specify the provider as one that this child is presently a student of, has a
              sibling of a currently enrolled student, or a family member of a current employee. If
              you continue without selecting this location, your priority at this site will not be
              ensured.
            </div>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="confirmNoGuaranteedSeatsDialog()"
      ref="noGuaranteedSeatsDialog"
      :cancellable="true"
      :closeable="true"
      :max-width="600"
      save-button-text="I understand"
      title="Seats are not guaranteed"
    >
      <template #form>
        <v-row data-testid="noGuaranteedSeatsDialog">
          <v-col cols="12">
            <div class="fs-14">
              {{
                $t(
                  'Adding this program to your list does not reserve the seat. While the program has seats available now, they may be filled prior to placement.',
                )
              }}
            </div>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>
  </FormQuestion>
</template>

<script>
import API from '@/shared/mixins/api';
import SequentialModals from '@/shared/mixins/sequential_modals';
import CurrentEnrollmentStep from '@/parent/components/subsidy/CurrentEnrollmentStep.vue';
import ContentDialog from '@/shared/components/ContentDialog.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import RankProgramsStep from '@/parent/components/subsidy/RankProgramsStep.vue';
import SelectProgramsStep from '@/parent/components/subsidy/SelectProgramsStep.vue';
import SiblingSelectionStep from '@/parent/components/subsidy/SiblingSelectionStep.vue';
import StaffSelectionStep from '@/parent/components/subsidy/StaffSelectionStep.vue';
import ProviderReferredProgramCard from '@/parent/components/subsidy/ProviderReferredProgramCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { SUBSIDY_EVENTS } from '@/parent/services/constants';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';

export default {
  compatConfig: { MODE: 2 },

  components: {
    ContentDialog,
    CurrentEnrollmentStep,
    FormQuestion,
    ProviderMatch,
    RankProgramsStep,
    ResourceDialog,
    SelectProgramsStep,
    SiblingSelectionStep,
    StaffSelectionStep,
    ProviderReferredProgramCard,
    VStepperVertical,
    VStepperVerticalItem,
  },

  mixins: [API, SequentialModals],

  props: {
    childData: {
      type: Object,
      default: null,
    },
    defaultQuery: {
      type: String,
      default: null,
    },
    hideActions: Boolean,
    initialStep: {
      type: Number,
      default: 1,
    },
    previousChildName: {
      type: String,
      default: null,
    },
    previousSubsidyProgramIds: {
      type: Array,
      default: null,
    },
    readonly: Boolean,
    processing: Boolean,
    showSelected: Boolean,
    subsidy: {
      type: Object,
      default: null,
    },
    subsidies: {
      type: Array,
      default: null,
    },
    subsidyProgram: {
      type: Object,
      default: null,
    },
    siblingProviders: {
      type: Array,
      default: () => [],
    },
    staffProviders: {
      type: Array,
      default: () => [],
    },
    currentProvider: {
      type: String,
      default: null,
    },
  },

  emits: [
    'back',
    'change',
    'next',
    'reorderPrograms',
    'selectedCurrentProviderId',
    'selectedProgramIds',
    'selectedSiblingIds',
    'selectedStaffIds',
  ],

  data() {
    return {
      providerSchema: this.$store.state.schemas[this.subsidyProgram.provider_schema_id],
      selectedProviders: [],
      siblingProviderIds: this.siblingProviders,
      staffProviderIds: this.staffProviders,
      currentProviderId: this.currentProvider,
      step: this.initialStep,
      pendingStep: null,
      siblingNotApplicable: false,
      staffNotApplicable: false,
      currentProviderNotApplicable: false,
      referredProvider: null,
      showReferredProviderCard: true,
    };
  },

  computed: {
    conditionalSteps() {
      let steps = [0, 'select', 'rank']; // first 2 values don't matter, but are added so we get the correct index of next steps
      if (this.subsidyProgram?.allow_enrolled) {
        steps.push('current_provider_id');
      }
      if (this.subsidyProgram?.enable_sibling_provider_selection) {
        steps.push('sibling_provider_ids');
      }
      if (this.subsidyProgram?.enable_staff_provider_selection) {
        steps.push('staff_provider_ids');
      }
      return steps;
    },

    currentProviderSelectionValid() {
      return !!this.currentProviderId || this.currentProviderNotApplicable === true;
    },

    providerSelectionContainsReferredProviderId() {
      const referredProviderId = this.referredProvider?.id;

      return (
        this.currentProviderId === referredProviderId ||
        this.siblingProviderIds.includes(referredProviderId) ||
        this.staffProviderIds.includes(referredProviderId)
      );
    },

    hasEligibleSeatsSelected() {
      // We need to match on each of the selectedProviders.programs and subsequently identify selected programs using subsidy.program_ids
      // then determine if the program contains a seatAvailability of either "available" or "low"
      // if there are any matches then return true

      const matches = [];

      this.selectedProviders.forEach((provider) => {
        const isMatched = this.subsidy.program_ids.some((programId) =>
          provider.programs.some(
            (providerProgram) =>
              providerProgram.id === programId &&
              (providerProgram.meta?.seat_availability_flag === 'available' ||
                providerProgram.meta?.seat_availability_flag === 'low'),
          ),
        );

        if (isMatched === true) {
          matches.push(isMatched);
        }
      });

      return matches.length > 0;
    },

    nextDisabled() {
      return (
        this.subsidy.program_ids.length === 0 ||
        (this.step < 3 && this.subsidyProgram.allow_enrolled)
      );
    },

    siblingSelectionValid() {
      return this.siblingProviderIds.length > 0 || this.siblingNotApplicable === true;
    },

    staffSelectionValid() {
      return this.staffProviderIds.length > 0 || this.staffNotApplicable === true;
    },
  },

  watch: {
    // We need to emit sibling/staff selections back to the parent subsidy component due to a bug
    // which does not allow us to use v-bind directly through two ancestors
    siblingProviderIds: {
      deep: true,
      handler(newVal) {
        this.$emit('selectedSiblingIds', newVal);
      },
    },

    staffProviderIds: {
      deep: true,
      handler(newVal) {
        this.$emit('selectedStaffIds', newVal);
      },
    },

    currentProviderId(newVal) {
      this.$emit('selectedCurrentProviderId', newVal);
    },

    'subsidy.program_ids': {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.loadSelectedProviders(newVal);
        this.updateReferredProgramVisibility(newVal);
      },
    },
  },

  created() {
    this.getProgram();
  },

  methods: {
    updateReferredProgramVisibility(programIds = []) {
      if (programIds.length === 0) return;

      const referredProgramId = this.$route?.query?.programId;
      const hasReferredProgramSelected = programIds.includes(referredProgramId);

      if (hasReferredProgramSelected) {
        this.showReferredProviderCard = false;
      } else {
        this.showReferredProviderCard = true;
      }
    },

    handleReferredProgramConfirm(programId) {
      this.showReferredProviderCard = false;
      this.toggleSelection(programId, true);
    },

    async getProgram() {
      const programId = this.$route?.query?.programId;

      if (programId) {
        const resp = await this.api.public_api.organization.provider.index({
          program_ids: [programId],
          include: 'details, programs',
          page_size: 200,
        });

        const providers = resp.data;
        const provider = providers[0];
        const referredProgram = provider?.programs?.filter((program) => program.id === programId);

        this.referredProvider = { ...provider, programs: referredProgram };
      }
    },

    validateAndNext() {
      if (this.currentProviderSelectionValid === false) {
        this.$eventBus.$emit(
          'chime',
          `A selection is required for ${this.subsidyProgram.current_provider_title}`,
        );
        return;
      }

      if (
        this.subsidyProgram.enable_sibling_provider_selection &&
        this.siblingSelectionValid === false
      ) {
        this.$eventBus.$emit(
          'chime',
          `A selection is required for ${this.subsidyProgram.sibling_provider_title}`,
        );
        return;
      }

      if (
        this.subsidyProgram.enable_staff_provider_selection &&
        this.staffSelectionValid === false
      ) {
        this.$eventBus.$emit(
          'chime',
          `A selection is required for ${this.subsidyProgram.staff_provider_title}`,
        );
        return;
      }

      if (this.referredProvider && this.providerSelectionContainsReferredProviderId === false) {
        this.$refs.noReferredProviderInSelectedProvidersDialog.open();
        return;
      }

      this.$emit('next');
    },

    confirmNoReferredProviderInProviderSelections() {
      this.$refs.noReferredProviderInSelectedProvidersDialog.close();
      this.$emit('next');
    },

    selectCurrentProvider(currentProviderId) {
      this.currentProviderId = currentProviderId;

      if (currentProviderId && this.currentProviderNotApplicable === true) {
        this.currentProviderNotApplicable = false;
      }
    },

    selectSiblings(selectedSiblingIds) {
      this.siblingProviderIds = selectedSiblingIds;

      if (selectedSiblingIds.length > 0 && this.siblingNotApplicable === true) {
        this.siblingNotApplicable = false;
      }
    },

    siblingNotApplicableClicked() {
      this.siblingNotApplicable = !this.siblingNotApplicable;

      if (this.siblingNotApplicable) {
        this.siblingProviderIds = [];
      }
    },

    selectStaff(selectedStaffIds) {
      this.staffProviderIds = selectedStaffIds;

      if (selectedStaffIds.length > 0 && this.staffNotApplicable === true) {
        this.staffNotApplicable = false;
      }
    },

    staffNotApplicableClicked() {
      this.staffNotApplicable = !this.staffNotApplicable;

      if (this.staffNotApplicable) {
        this.staffProviderIds = [];
      }
    },

    currentProviderNotApplicableClicked() {
      this.currentProviderNotApplicable = !this.currentProviderNotApplicable;

      if (this.currentProviderNotApplicable) {
        this.currentProviderId = null;
      }
    },

    changeStep(stepNumber) {
      this.step = Math.max(0, Math.min(stepNumber, this.conditionalSteps.length - 1));
    },

    showNextOnStep(currentStep) {
      return currentStep + 1 < this.conditionalSteps.length;
    },

    validateStepChange(stepNumber) {
      const referredProgramId = this.referredProvider?.programs[0]?.id;
      const hasReferredProgramSelected = this.subsidy.program_ids.includes(referredProgramId);

      const referredProgramValidator =
        this.step === 1 && referredProgramId && !hasReferredProgramSelected;
      const seatsAvailabilityValidator = this.step === 1 && this.hasEligibleSeatsSelected;

      return this.handleSequentialModals({
        modalRef: this.$refs.noReferredProgramSelectedDialog,
        modalValidator: referredProgramValidator,
        nextModalRef: this.$refs.noGuaranteedSeatsDialog,
        nextModalValidator: seatsAvailabilityValidator,
        proceedFn: () => this.changeStep(stepNumber),
      });
    },

    confirmNoGuaranteedSeatsDialog() {
      this.handleNextOrCloseSequentialModal();
    },

    confirmNoReferredProgram() {
      this.handleNextOrCloseSequentialModal();
    },

    copyPreferences() {
      // eslint-disable-next-line vue/no-mutating-props
      this.subsidy.program_ids = JSON.parse(JSON.stringify(this.previousSubsidyProgramIds));
      this.$emit('change', this.subsidy.program_ids);
      this.viewSelectedResults = true;
    },

    async loadSelectedProviders(programIds = []) {
      if (programIds.length === 0) return;
      // Use programIds arg for values to prevent race condition in state
      // subsidy.program_ids updating

      const resp = await this.api.public_api.organization.provider.index({
        program_ids: programIds,
        subsidy_program_id: this.subsidyProgram.id,
        include: 'details,programs',
        page_size: 200,
      });

      this.selectedProviders = resp.data;
    },

    openProvider(providerId) {
      this.api.public_api.provider.get(providerId, (resp) => {
        this.$refs.providerDialog.open(resp.data);
      });
    },

    remove(programId) {
      this.$emit('selectedProgramIds', { type: SUBSIDY_EVENTS.REMOVE, id: programId });
    },

    verifySelectionLimit(programId) {
      if (!this.subsidyProgram.selection_limit) return;

      if (
        this.subsidyProgram.selection_limit > 0 &&
        this.subsidyProgram.selection_limit <= this.subsidy.program_ids.length &&
        !this.subsidy.program_ids.includes(programId[0])
      ) {
        this.$eventBus.$emit('chime', this.$t('Exceeded selection limit'));
      }
    },

    toggleSelection(programId, newVal) {
      if (newVal === true) {
        if (!this.subsidy.program_ids.includes(programId)) {
          this.$emit('selectedProgramIds', { type: SUBSIDY_EVENTS.ADD, id: programId });
        }
      }

      if (newVal === false && this.subsidy.program_ids.includes(programId)) {
        this.$emit('selectedProgramIds', { type: SUBSIDY_EVENTS.REMOVE, id: programId });
      }

      this.$emit('change', this.subsidy.program_ids);
    },
  },
};
</script>
